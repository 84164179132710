import { User } from '@app/core/models';

class ServiceFee {
  type?: string;
  inEur?: number;
  inPct?: number;
}

export interface UuvaRoleConfig {
  uuvaPrincipalId: string;
  uuvaInvoiceTemplateId: string;
  uuvaAccountId: string;
  uuvaAccountTxId: string;
}

export interface UuvaConfig {
  freelancer: UuvaRoleConfig;
  soletrader: UuvaRoleConfig;
  company: UuvaRoleConfig;
  taxiCompany: UuvaRoleConfig;
}

export interface I18nObject {
  'en-US': string;
  'sv-SE': string;
  'et-EE': string;
  'fi-FI': string;
}

export interface RoleTermsAndConditions {
  htmlText1: I18nObject;
  htmlText2: I18nObject;
  htmlTitle1: I18nObject;
  htmlTitle2: I18nObject;
  lastUpdated: I18nObject;
}

export interface TermsAndConditions {
  freelancer: RoleTermsAndConditions;
  soletrader: RoleTermsAndConditions;
  company: RoleTermsAndConditions;
}

export interface AccountType {
  debitAccount: string;
  creditAccount: string;
}

export interface BookingAccount {
  soletrader: AccountType;
  company: AccountType;
  taxiCompany: AccountType;
}

export interface AccountingEventsBookingAccount {
  eventName: string;
  configuration: BookingAccount;
}

export class Features {
  aiFeatureChatbase?: string;
  aiFeatureCreateInvoice?: string;
  clientCustomization?: string;
  clientsEmailRequired?: string;
  clientsReview?: string;
  contractorLiabilityData?: string;
  contractorLiabilityAMLData?: string;
  contractorLiabilityPEPDataAndSanctionLists: string;
  creditCheck?: string;
  dashboardInfoBox?: string;
  dashboardInviteFriends?: string;
  expensesSimplifiedManagement?: string;
  faq?: string;
  insuranceAccidents?: string;
  insuranceFee?: string;
  insuranceLiabilityAndLegal?: string;
  insurances?: string;
  invoicesFastInvoiceSending?: string;
  invoicesFastInvoiceStatusCheck?: string;
  invoicesOperatorMarkAsPaid?: string;
  invoicesPaymentRemindersSelection?: string;
  invoicesQuickSalary?: string;
  invoicesQuickSalaryOperatorFunding?: string;
  jobs?: string;
  notificationsSms?: string;
  payoutsDebtCollectionBankTransfers: string;
  payoutsUrgentBankTransfers?: string;
  publicApiStatusMetrics?: string;
  rolesCompany?: string;
  rolesFreelancer?: string;
  settlementsAddServiceFee?: string;
  supportMessages?: string;
  taxiAutocab?: string;
  taxiContracts?: string;
  taxiFeatures?: string;
  taxiRides?: string;
  usersAutomaticUsername?: string;
  usersFees?: string;
  usersForceSocialSecurityFee?: string;
  usersForceYel?: string;
  usersLoginViaFacebook?: string;
  usersLoginViaGoogle?: string;
  usersLoginViaLinkedIn?: string;
  usersLoginViaPlusId?: string;
  usersRegistration?: string;
  usersSkipIdVerification?: string;
  usersTalentProfile?: string;
  usersYelDocumentFileId?: string;
  usersYelDocumentUrl?: string;
  usersYelFromOperator?: string;
  veroMonthlyReports?: string;
  workNotices?: string;
  workShifts?: string;
  workTime?: string;
}

class Configuration {
  insuranceFees: [ServiceFee];
  serviceFees: [ServiceFee];
  minServiceFee: number;
  maxServiceFee: number;
  newUserRegistrationInvoicingThreshold: number;
  newUserRegistrationBonus: number;
  newUserReferrerBonus: number;
  creditCheckFeeInEur: number;
  quickSalaryPaymentFeeInEur: number;
  quickSalaryPaymentFeeInPct: number;
  urgentBankTransferFeeInEur: number;
  defaultVatClass?: string;
}

class RootConfiguration {
  operatorCompanyUser: string;
  serviceFees: [ServiceFee];
  apiTaxCardFeePerTx: number;
  apiIncomeReportFeePerTx: number;
  apiSalaryCalcFeePerTx: number;
  apiInvoiceFeePerTx: number;
  apiInvoiceFinanceFeePerTx: number;
  apiCostPerTxData: number;
  creditCheckFeePerTx: number;
  debtCollectionBankTransferFeeInEur: number;
  rootQuickSalaryPaymentFeeInEur: number;
  rootQuickSalaryPaymentFeeInPct: number;
  salaryPayoutFeeInEur: number;
  monthlyIdVerificationFeeInEur: number;
  monthlyAiInvoiceFeeInEur: number;
  monthlyAiChatbotFeeInEur: number;
  invoicingSystem: string;
  reminderSystem: string;
  debtCollectionSystem: string;
  accountingSystem: string;
  salaryPaymentSystem: string;

  uuvaConfig: UuvaConfig;

  plusIdApiKey: string;
  aaltoBankAccountIban?: string;
  aaltoBankAccountBic?: string;
  aaltoBankAccountOwner?: string;
  aaltoBankAccountInformation?: string;
  smsCost: number;
  sendgridTemplateId?: string;
  chatbaseChatId?: string;
  chatGptApiKey?: string;
  chatScript?: string;
}

export interface ClientCustomization {
  clientName: string;
  clientIdentifier: string;
  // branding
  logoUrl?: string;
  colorPrimary?: string;
  colorSecondary?: string;
  colorAccent?: string;
  logo?: string;
  iconUrl?: string;
  icon?: string;
  customCss?: string;
  customJs?: string;
  // settings
  dueInDays?: number;
  vatClass?: string;
  // others
  additionalData?: Record<string, any>;
}

export class Operator {
  _id?: string;
  publicId?: string;
  shortName?: string;
  serviceName?: string;
  baseUrl?: string;
  companyName?: string;
  companyId?: string;
  companyVatId?: string;
  streetAddress1?: string;
  streetAddress2?: string;
  postalCode?: string;
  city?: string;
  stateOrProvince?: string;
  countryCode?: string;
  supportAddress?: string;
  supportPhone?: string;
  supportInstructions?: string;
  supportEmail?: string;
  supportUrl?: string;
  supportWelcomeMessage?: any;
  supportWelcomeMessageSoletrader?: any;
  operatorInfoBox?: any;
  contactFullName?: string;
  contactEmail?: string;
  contactPhoneNumber?: string;
  contactTitle?: string;

  bankAccountIban?: string;
  bankAccountBic?: string;
  bankAccountOwner?: string;
  bankAccountInformation?: string;

  debtCollectionSentToBankAccountIban?: string;
  debtCollectionSentToBankAccountBic?: string;
  debtCollectionSentToBankAccountOwner?: string;
  debtCollectionSentToBankAccountInformation?: string;

  colorFg1?: string;
  colorFg2?: string;
  colorFg3?: string;
  colorBg1?: string;
  colorBg2?: string;
  colorToolbar?: string;
  colorMainMenu?: string;
  colorMainHeader?: string;
  colorMenuHeader?: string;
  colorInfo?: string;
  colorWarn?: string;
  colorSuccess?: string;
  colorError?: string;
  titleFont?: string;
  bodyFont?: string;
  logoUrl?: string;
  logoFileId?: string;
  iconUrl?: string;
  iconFileId?: string;
  favIconUrl?: string;
  backgroundImage?: string;
  createdAt?: string;

  privacy: TermsAndConditions;
  terms: TermsAndConditions;

  features?: Features;
  configuration?: Configuration;
  rootConfiguration?: RootConfiguration;
  accountingEventsBookingAccounts?: AccountingEventsBookingAccount[];
  clientCustomization?: ClientCustomization[];

  createdByUser?: User;
  wallets?: [];
  apiKeys?: [];


  /**
   * Constructor
   *
   * @param operator
   */
  constructor(operator?) {
    this._id = operator?._id;
    this.createdAt = operator?.createdAt;
    this.createdByUser = operator?.createdByUser;
    this.publicId = operator?.publicId || '';
    this.shortName = operator?.shortName || '';
    this.serviceName = operator?.serviceName || '';
    this.baseUrl = operator?.baseUrl || '';
    this.companyName = operator?.companyName || '';
    this.companyId = operator?.companyId || '';
    this.companyVatId = operator?.companyVatId || '';
    this.streetAddress1 = operator?.streetAddress1 || '';
    this.streetAddress2 = operator?.streetAddress2 || '';
    this.postalCode = operator?.postalCode || '';
    this.city = operator?.city || '';
    this.stateOrProvince = operator?.stateOrProvince || '';
    this.countryCode = operator?.countryCode || '';
    this.supportAddress = operator?.supportAddress || '';
    this.supportInstructions = operator?.supportInstructions || '';
    this.supportPhone = operator?.supportPhone || '';
    this.supportEmail = operator?.supportEmail || '';
    this.supportUrl = operator?.supportUrl || '';
    this.supportWelcomeMessage = operator?.supportWelcomeMessage || {};
    this.supportWelcomeMessageSoletrader = operator?.supportWelcomeMessageSoletrader || {};
    this.operatorInfoBox = operator?.operatorInfoBox || '';
    this.contactFullName = operator?.contactFullName || '';
    this.contactEmail = operator?.contactEmail || '';
    this.contactPhoneNumber = operator?.contactPhoneNumber || '';
    this.contactTitle = operator?.contactTitle || '';

    this.bankAccountIban = operator?.bankAccountIban || '';
    this.bankAccountBic = operator?.bankAccountBic || '';
    this.bankAccountOwner = operator?.bankAccountOwner || '';
    this.bankAccountInformation = operator?.bankAccountInformation || '';

    this.debtCollectionSentToBankAccountIban = operator?.debtCollectionSentToBankAccountIban || '';
    this.debtCollectionSentToBankAccountBic = operator?.debtCollectionSentToBankAccountBic || '';
    this.debtCollectionSentToBankAccountOwner = operator?.debtCollectionSentToBankAccountOwner || '';
    this.debtCollectionSentToBankAccountInformation = operator?.debtCollectionSentToBankAccountInformation || '';

    this.colorFg1 = operator?.colorFg1 || '';
    this.colorFg2 = operator?.colorFg2 || '';
    this.colorFg3 = operator?.colorFg3 || '';
    this.colorBg1 = operator?.colorBg1 || '';
    this.colorBg2 = operator?.colorBg2 || '';
    this.bodyFont = operator?.bodyFont || '';
    this.colorToolbar = operator?.colorToolbar;
    this.colorMainMenu = operator?.colorMainMenu;
    this.colorMainHeader = operator?.colorMainHeader;
    this.colorMenuHeader = operator?.colorMenuHeader;
    this.colorInfo = operator?.colorInfo;
    this.colorWarn = operator?.colorWarn;
    this.colorSuccess = operator?.colorSuccess;
    this.colorError = operator?.colorError;
    this.titleFont = operator?.titleFont || '';
    this.logoUrl = operator?.logoUrl || '';
    this.logoFileId = operator?.logoFileId || '';
    this.iconUrl = operator?.iconUrl || '';
    this.iconFileId = operator?.iconFileId || '';
    this.favIconUrl = operator?.favIconUrl || '';
    this.backgroundImage = operator?.backgroundImage || '';

    this.configuration = operator?.configuration || {};
    this.privacy = operator?.privacy || {};
    this.terms = operator?.terms || {};

    this.features = operator?.features || {
      aiFeatureChatbase: 'disabled',
      aiFeatureCreateInvoice: 'disabled',
      clientCustomization: 'disabled',
      clientsEmailRequired: 'enabled',
      clientsReview: 'enabled',
      contractorLiabilityData: 'disabled',
      contractorLiabilityAMLData: 'disabled',
      contractorLiabilityPEPDataAndSanctionLists: 'disabled',
      creditCheck: 'disabled',
      dashboardInfoBox: 'enabled',
      dashboardInviteFriends: 'enabled',
      expensesSimplifiedManagement: 'disabled',
      faq: 'enabled',
      insuranceAccidents: 'enabled',
      insuranceFee: 'enabled',
      insuranceLiabilityAndLegal: 'enabled',
      insurances: 'disabled',
      invoicesFastInvoiceSending: 'disabled',
      invoicesFastInvoiceStatusCheck: 'disabled',
      invoicesOperatorMarkAsPaid: 'disabled',
      invoicesPaymentRemindersSelection: 'disabled',
      invoicesQuickSalary: 'enabled',
      invoicesQuickSalaryOperatorFunding: 'disabled',
      jobs: 'disabled',
      notificationsSms: 'disabled',
      payoutsDebtCollectionBankTransfers: 'enabled',
      payoutsUrgentBankTransfers: 'enabled',
      publicApiStatusMetrics: 'disabled',
      rolesCompany: 'disabled',
      rolesFreelancer: 'enabled',
      settlementsAddServiceFee: 'enabled',
      supportMessages: 'enabled',
      taxiAutocab: 'disabled',
      taxiContracts: 'disabled',
      taxiFeatures: 'disabled',
      taxiRides: 'disabled',
      usersAutomaticUsername: 'enabled',
      usersFees: 'disabled',
      usersForceSocialSecurityFee: 'disabled',
      usersForceYel: 'disabled',
      usersLoginViaFacebook: 'coming_soon',
      usersLoginViaGoogle: 'coming_soon',
      usersLoginViaLinkedIn: 'coming_soon',
      usersLoginViaPlusId: 'enabled',
      usersRegistration: 'enabled',
      usersSkipIdVerification: 'disabled',
      usersTalentProfile: 'enabled',
      usersYelDocumentFileId: '',
      usersYelDocumentUrl: '',
      usersYelFromOperator: 'disabled',
      veroMonthlyReports: 'disabled',
      workNotices: 'disabled',
      workShifts: 'disabled',
      workTime: 'enabled'
    };

    this.configuration = operator?.configuration || {
      serviceFees: [
        { type: 'email', inEur: 0.0, inPct: 6.5 },
        { type: 'ovt', inEur: 0.0, inPct: 6.5 },
        { type: 'paper', inEur: 0.0, inPct: 6.5 }
      ],
      minServiceFee: 0,
      maxServiceFee: 100000,
      newUserRegistrationInvoicingThreshold: 0.00,
      newUserRegistrationBonus: 0.00,
      newUserReferrerBonus: 0.00,
      creditCheckFeeInEur: 5.00,
      quickSalaryPaymentFeeInEur: 0.00,
      quickSalaryPaymentFeeInPct: 5.0,
      urgentBankTransferFeeInEur: 0.00,
      defaultVatClass: 'vat25_5',
    };

    this.rootConfiguration = operator?.rootConfiguration || {
      operatorCompanyUser: null,
      serviceFees: [
        { type: 'email', inEur: 0.49, inPct: 0.0 },
        { type: 'ovt', inEur: 0.49, inPct: 0.0 },
        { type: 'paper', inEur: 1.39, inPct: 0.0 }
      ],
      apiTaxCardFeePerTx: 0,
      apiIncomeReportFeePerTx: 0,
      apiSalaryCalcFeePerTx: 0,
      apiInvoiceFeePerTx: 0,
      apiInvoiceFinanceFeePerTx: 0,
      // api costs
      apiCostPerTxData: 0.1,
      // other costs
      creditCheckFeePerTx: 0,
      debtCollectionBankTransferFeeInEur: 1.00,
      rootQuickSalaryPaymentFeeInEur: 0,
      rootQuickSalaryPaymentFeeInPct: 2,
      salaryPayoutFeeInEur: 5,
      monthlyIdVerificationFeeInEur: 150,
      monthlyAiInvoiceFeeInEur: 100,
      monthlyAiChatbotFeeInEur: 100,

      invoicingSystem: 'uuva',
      reminderSystem: 'uuva',
      debtCollectionSystem: 'uuva',
      accountingSystem: 'uuva',
      salaryPaymentSystem: 'automatically',
      uuvaConfig: ['freelancer', 'soletrader', 'company', 'taxiCompany']
        .reduce((acc, elem) => Object.assign(acc, {
          [elem]: {
            uuvaPrincipalId: '',
            uuvaInvoiceTemplateId: '',
            uuvaAccountId: '',
            uuvaAccountTxId: ''
          }
        }), {}),
      aaltoBankAccountIban: '',
      aaltoBankAccountBic: '',
      aaltoBankAccountOwner: '',
      aaltoBankAccountInformation: '',
      smsCost: 0.29,
      chatScript: '',
      sendgridTemplateId: '',
      chatbaseChatId: '',
      chatGptApiKey: ''
    };

    this.accountingEventsBookingAccounts = operator?.accountingEventsBookingAccounts || [];
    this.clientCustomization = operator?.clientCustomization || [];

    this.wallets = operator?.wallets || [];
    this.apiKeys = operator?.apiKeys || [];
  };
}
