import { User, ClientRegister } from '@app/core/models';

export class Client {
  _id?: string;
  uuid?: string;
  createdAt?: Date;
  contactFirstname?: string;
  contactLastname?: string;
  contactEmail?: string;
  contactMobilePhone?: string;
  contactLocale?: string;
  externalId?: string;
  automaticInvoiceApprovalLimit?: number;
  invoiceRemindersEnabled?: boolean;
  status?: string;
  preferredInvoiceMedia?: string;
  invoiceDueInDays?: number;
  invoicingEmail?: string;
  ovtOperator?: string;
  ovtAddress?: string;
  tags?: string[];

  createdByUser?: User;
  clientRegister?: ClientRegister;
  owner?: User;
  linkedToUser?: User;

  /**
   * Constructor
   *
   * @param client
   */
  constructor(client?) {
    this._id = client._id || '';
    this.uuid = client.uuid || '';
    this.createdAt = client.createdAt;
    this.contactFirstname = client.contactFirstname || '';
    this.contactLastname = client.contactLastname || '';
    this.contactEmail = client.contactEmail || '';
    this.contactMobilePhone = client.contactMobilePhone || '';
    this.contactLocale = client.contactLocale || '';
    this.externalId = client.externalId || '';
    this.automaticInvoiceApprovalLimit = client.automaticInvoiceApprovalLimit || 0;
    this.invoiceRemindersEnabled = client.invoiceRemindersEnabled;
    this.status = client.status || '';
    this.preferredInvoiceMedia = client.preferredInvoiceMedia || 'email';
    this.invoiceDueInDays = client.invoiceDueInDays || 14;
    this.invoicingEmail = client.invoicingEmail || '';
    this.ovtOperator = client.ovtOperator || '';
    this.ovtAddress = client.ovtAddress || '';
    this.tags = client.tags || [];
    this.createdByUser = client.createdByUser;
    this.owner = client.owner;
    this.clientRegister = client.clientRegister || {};
  }
}
