import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import Bugsnag from '@bugsnag/js'
import { lastValueFrom } from 'rxjs';
import * as WebFont from 'webfontloader';

import { environment } from '@env/environment';
import { AppSettingsConfig } from '@app/core/configs/app-settings.config';
import { AuthenticationService, ApiService } from '@app/core/services';

declare const tinycolor: any;

@Injectable({
  providedIn: 'root'
})
export class AppInitializerService {
  constructor(
    private titleService: Title,
    private authService: AuthenticationService,
    private apiService: ApiService,
    private appConfig: AppSettingsConfig
  ) { }

  async initializeApp(): Promise<any> {
    try {
      await this.getBrand();
      return this.authService.checkIfUserTokenAlreadyExists();
    } catch (err) {
      console.error('initializeApp() catch; err:', err);
      throw err;
    }
  }

  async getBrand(retries = 20) {
    console.log('AppInitializerService/getBrand()');
    try {
      // get brand
      const brand = await lastValueFrom(this.apiService.getBrand());
      console.log('getBrand() brand:', brand);
      // define theme based on brand
      let loadedTheme: any = brand ? {
        primary: brand.colorFg1 || this.appConfig.theme.primary,
        secondary: brand.colorFg2 || this.appConfig.theme.secondary,
        accent: brand.colorFg3 || this.appConfig.theme.accent,
        bg1: brand.colorBg1 || this.appConfig.theme.bg1,
        bg2: brand.colorBg2 || this.appConfig.theme.bg2,
        'main-content': brand.colorBg1 || this.appConfig.theme.bg1,
        card: brand.colorBg2 || this.appConfig.theme.bg2,
        plusIdBlue: this.appConfig.theme.plusIdBlue,
        logoUrl: brand.logoUrl || this.appConfig.theme.logoUrl,
        iconUrl: brand.iconUrl || this.appConfig.theme.iconUrl,
        favIconUrl: brand.favIconUrl || this.appConfig.theme.favIconUrl,
        titleFont: brand.titleFont || this.appConfig.theme.titleFont,
        bodyFont: brand.bodyFont || this.appConfig.theme.bodyFont,
        backgroundImage: brand.backgroundImage || this.appConfig.theme.backgroundImage,
        containersOpacity: brand.backgroundImage && brand.backgroundImage !== '' ? 0.95 : 1
      } : this.appConfig.theme;

      loadedTheme = {
        ...loadedTheme,
        toolbar: brand.colorToolbar || loadedTheme.primary,
        'main-menu': brand.colorMainMenu || loadedTheme.primary,
        'menu-header': brand.colorMenuHeader || loadedTheme.secondary,
        'main-header': brand.colorMainHeader || loadedTheme.secondary,
        info: brand.colorInfo || this.appConfig.theme.info,
        warn: brand.colorWarn || this.appConfig.theme.warn,
        success: brand.colorSuccess || this.appConfig.theme.success,
        error: brand.colorError || loadedTheme.accent
      };
      this.useTheme(loadedTheme);

      // set operator
      this.setOperator(brand, loadedTheme);
    } catch (err) {
      if (retries > 0) {
        setTimeout(_ => this.getBrand(retries - 1), 3000);
      }

      // otherwise use default theme
      this.useTheme(this.appConfig.theme);
      console.log('app-init() Using default theme');
    }
  }

  private getColorObject(color, number, type) {
    const hexColor = color.substring(color.indexOf('#'), color.indexOf('#') + 7);
    console.log
    const c = tinycolor(hexColor);
    const key1 = `--color-${type}${number ? '-' + number : ''}`;
    const value1 = color;//c.toHexString();
    const key2 = `--color-${type}-contrast${number ? '-' + number : ''}`;
    const value2 = c.isLight() ? '#333333' : 'white';
    document.documentElement.style.setProperty(key1, value1);
    document.documentElement.style.setProperty(key2, value2);
  }

  private setDark(isDark) {
    // Color theme - Use normal for loop for IE11 compatibility
    for (let i = 0; i < document.body.classList.length; i++) {
      const className = document.body.classList[i];

      if (className.startsWith('theme-')) {
        document.body.classList.remove(className);
      }
    }

    document.body.classList.add('theme-default');//isDark ? 'theme-dark' : 'theme-default');
  }

  private useTheme(theme) {
    const { logoUrl, iconUrl, favIconUrl, titleFont, bodyFont, backgroundImage, containersOpacity, ...colors } = theme;
    document.documentElement.style.setProperty('--logo-url', 'url(' + logoUrl + ')');
    document.documentElement.style.setProperty('--icon-url', 'url(' + iconUrl + ')');
    document.documentElement.style.setProperty('--background-image', 'url(' + backgroundImage + ')');
    document.documentElement.style.setProperty('--containers-opacity', containersOpacity);
    document.documentElement.style.setProperty('--title-font', titleFont);
    document.documentElement.style.setProperty('--body-font', bodyFont);
    // load fonts
    WebFont.load({
      google: {
        families: [titleFont, bodyFont]
      }
    });
    // set favicon
    document?.getElementById('favico')?.setAttribute('href', favIconUrl);
    // set dark mode
    this.setDark(tinycolor(colors.bg1).isDark());

    // set colors
    for (const type in colors) {
      if (['primary', 'secondary', 'accent', 'plusIdBlue'].includes(type)) {
        /*this.getColorObject(tinycolor(colors[type]).lighten(52), '50', type);
        this.getColorObject(tinycolor(colors[type]).lighten(37), '100', type);
        this.getColorObject(tinycolor(colors[type]).lighten(26), '200', type);
        this.getColorObject(tinycolor(colors[type]).lighten(12), '300', type);
        this.getColorObject(tinycolor(colors[type]).lighten(6), '400', type);*/
        this.getColorObject(colors[type], null, type);
        /*this.getColorObject(tinycolor(colors[type]).darken(6), '600', type);
        this.getColorObject(tinycolor(colors[type]).darken(12), '700', type);
        this.getColorObject(tinycolor(colors[type]).darken(18), '800', type);
        this.getColorObject(tinycolor(colors[type]).darken(24), '900', type);
        this.getColorObject(tinycolor(colors[type]).lighten(50).saturate(30), 'A100', type);
        this.getColorObject(tinycolor(colors[type]).lighten(30).saturate(30), 'A200', type);
        this.getColorObject(tinycolor(colors[type]).lighten(10).saturate(15), 'A400', type);
        this.getColorObject(tinycolor(colors[type]).lighten(5).saturate(5), 'A700', type);*/
      } else {
        this.getColorObject(colors[type], null, type);
      }
    }
  }

  private setOperator(operator, loadedTheme) {
    this.appConfig.operator = operator;
    this.appConfig.baseUrl = operator.baseUrl;
    this.appConfig.baseApiUrl = operator.baseApiUrl;
    this.appConfig.serviceName = operator.serviceName || operator.companyName || operator.shortName;
    this.appConfig.operatorName = operator.companyName || operator.serviceName || operator.shortName;
    this.appConfig.shortName = operator.shortName;
    this.appConfig.theme = loadedTheme;
    this.appConfig.countryList = operator.countryList;
    this.appConfig.expensesConfig = operator.expensesConfig;
    console.log('set appConfig; expenses config for: ' + this.appConfig.expensesConfig.year);
    this.appConfig.google = operator.google;
    this.appConfig.yelThreshold = operator.expensesConfig?.generic?.yelLowIncomeThreshold || operator.yelThreshold;
    this.appConfig.minRowNetPriceForConstruction = operator.minRowNetPriceForConstruction;
    this.appConfig.currency = operator.configuration.currency;

    // set appConfig as part of operator data
    this.appConfig.appConfig = operator.appConfig;

    if (this.appConfig.operatorName && environment.bugsnagEnabled) {
      // Set operator info for Bugsnag error reporter
      Bugsnag.addMetadata('operator', {
        operatorName: this.appConfig.operatorName
      });
    }

    // update page title
    const pageTitle = this.appConfig.serviceName + (operator.companyName ? ' | ' + operator.companyName : '');
    this.titleService.setTitle(pageTitle);
  }
}
