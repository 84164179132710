import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { AppSettingsConfig } from '@app/core/configs/app-settings.config';
import { User, BusinessProfile, UserProfile } from '@app/core/models';
import { NavigationService } from '@app/core/services';

@Injectable({
  providedIn: 'root'
})
export class CurrentUserService {
  private loggedIn = new BehaviorSubject<boolean>(false);
  private user = new BehaviorSubject<User>({});

  constructor(
    private appConfig: AppSettingsConfig,
    private _navigationService: NavigationService,
  ) { }

  currentUser(): Observable<User> {
    return this.user.asObservable();
  }

  getUser(): User {
    return this.user.value;
  }

  get isLoggedIn() {
    return this.loggedIn.asObservable();
  }

  remove() {
    console.log('CurrentUserService/remove()');
    try {
      // update navigation based on current user
      this._navigationService.resetNavigation();
    } catch (err) {
      console.log('remove() catch in resetMenu; err:', err);
    }
    this.loggedIn.next(false);
    this.user.next(null!);
  }

  updateUser(user: User) {
    const userData = { ...this.getUser(), ...user };
    setTimeout(_ =>  this._navigationService.updateMenuPerCurrentUser(userData), 200);
    this.user.next(userData);
  }

  updateBusinessProfile(businessProfile: BusinessProfile) {
    this.user.next({
      ...this.user.value,
      businessProfile: { ...this.user.value.businessProfile, ...businessProfile }
    });
  }

  updateUserProfile(userProfile: UserProfile) {
    const userData = {
      ...this.user.value,
      userProfile: { ...this.user.value.userProfile, ...userProfile }
    };
    setTimeout(_ => this._navigationService.updateMenuPerCurrentUser(userData), 200);

    this.user.next(userData);
  }

  setUser(user: User) {
    this.extendUserWithRolesGroup(user);
    this._navigationService.updateMenuPerCurrentUser(user, this.appConfig.operator);
    this.user.next({ ...user });
  }

  setLoggedIn(loggedIn: boolean) {
    this.loggedIn.next(loggedIn);
  }

  extendUserWithRolesGroup(user: User) {
    Object.assign(user, {
      isRoot: User.isRoot(user.role),
      isFinance: User.isFinance(user.role),
      isOperator: User.isOperator(user.role),
      isAdmin: User.isAdmin(user.role),
      isAccounting: User.isAccounting(user.role),
      isNonAdmin: !User.isAdmin(user.role),
      isFreelancer: User.isFreelancer(user.role),
      isBusiness: User.isBusiness(user.role),
      isSoletrader: User.isSoletrader(user.role),
      isCompany: User.isCompany(user.role),
      isTaxi: User.isTaxi(user.role),
      isTaxiUser: User.isTaxiUser(user.role),
      isTaxiAdmin: User.isTaxiAdmin(user.role)
    });
  }

  
}