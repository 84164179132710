import { HistoryItem } from "./business-statement.interface";
import { Operator, User } from "@app/core/models";

type BusinessRegistryType = 'commercial' | 'prepayment' | 'employer' | 'vat';

export interface BusinessRegistry {
  type: BusinessRegistryType;
  status: string;
  registeredAt: Date;
  registeredYear: number;
  authority: string;
  registryData: string;
};

interface BusinessInfo {
  dunsNumber: string;
  tradenames: [string];
  annualNetSales: number;
  hasEmployees: boolean;
  numOfEmployees: number;
  shareCapital: number;
  numOfShares: number;
  employeeCitizenships: [string];
  employeeTitles: [string];
}

export interface Compliance {
  type: string;
  status: string;
  lastConfirmationAt: Date;
  source: string;
}

export interface Owner {
  type: string;
  name: string;
  idNumber: string;
  ownershipPct: number;
}

export interface Management {
  name: string;
  role: string;
}

export interface BusinessProhibition {
  name: string;
  reason: string;
  validFrom: Date;
  validTo: Date;
}

export interface OccupationalHealthCare {
  organization: string;
  details: string;
  expiresAt: Date;
}

export interface Activities {
  status: string;
  countries: [string];
}

type TypeOfPartnership = 'client' | 'supplier' | 'partner';

export interface BusinessPartner {
  name: string;
  companyId: string;
  country: string;
  typeOfPartnership: TypeOfPartnership
}

type PensionInsuranceProvider = 'Ilmarinen' | 'Varma' | 'Elo' | 'Veritas' | 'Keva' | 'Other';

export interface EmployeePensionInsurance {
  paid: boolean;
  fileId: string;
  provider: PensionInsuranceProvider;
  otherProvider: string;
}

type AccidentInsuranceProvider = 'IF Vahinkovakuutusyhtiö' | 'LähiTapiola' | 'Pohjola Insurance' |
  'Fennia' | 'Turva' | 'Alandia' | 'Folksam' | 'Other';

export interface AccidentInsurance {
  active: boolean;
  provider: AccidentInsuranceProvider;
  otherProvider: string;
  coverageAmount: number;
  coverageArea: string;
  fileId: string;
}

type OccupationalHealthCareProvider = 'Terveystalo' | 'Mehiläinen' | 'Pihlajalinna' | 'Aava' | 'Diacor' | 'Other';

export interface OccupationalHealthCare {
  active: boolean;
  provider: OccupationalHealthCareProvider
  otherProvider: string;
  fileId: string;
}

type LiabilityInsuranceProvider = 'IF Vahinkovakuutusyhtiö' | 'LähiTapiola' | 'Pohjola Insurance' |
  'Fennia' | 'Turva' | 'Alandia' | 'Folksam' | 'Other';

export interface LiabilityInsurance {
  active: boolean;
  provider: LiabilityInsuranceProvider;
  otherProvider: string;
  validFrom: Date;
  validTo: Date;
  coverageAmount: number;
  coverageArea: string;
  fileId: string
}

type CollectiveAgreementName = 'technology1' | 'technology2' | 'technology3' |
  'construction1' | 'construction2' | 'construction3' |
  'retail' |
  'transport1' | 'transport2' |
  'social' |
  'information' |
  'other';

export interface CollectiveAgreement {
  active: boolean;
  name: CollectiveAgreementName;
  otherProvider: string;
  fileId: string;
}

export class LiabilityData {
  _id: string;

  lastConfirmedByUserAt: Date;

  businessInfo: BusinessInfo;

  employeePensionInsurance: EmployeePensionInsurance;
  accidentInsurance: AccidentInsurance;
  occupationalHealthCare: OccupationalHealthCare;
  liabilityInsurance: LiabilityInsurance;
  collectiveAgreement: CollectiveAgreement;

  businessRegistries: [BusinessRegistry];

  compliancies: [Compliance];
  owners: [Owner];
  managements: [Management];
  businessProhibitions: [BusinessProhibition];
  importActivities: Activities;
  exportActivities: Activities;
  businessPartners: [BusinessPartner];

  isBusinessInfoCompleted: boolean;
  isComplianciesCompleted: boolean;
  isOwnersCompleted: boolean;
  isManagementsCompleted: boolean;

  history: [HistoryItem];

  /* Model references */
  user: User;
  operator: Operator;

  constructor(liabilityData?: LiabilityData) {
    this._id = liabilityData._id;
    this.lastConfirmedByUserAt = liabilityData.lastConfirmedByUserAt;

    this.businessInfo = liabilityData.businessInfo;

    this.employeePensionInsurance = liabilityData.employeePensionInsurance;
    this.accidentInsurance = liabilityData.accidentInsurance;
    this.occupationalHealthCare = liabilityData.occupationalHealthCare;
    this.liabilityInsurance = liabilityData.liabilityInsurance;
    this.collectiveAgreement = liabilityData.collectiveAgreement;

    this.businessRegistries = liabilityData.businessRegistries;
    this.compliancies = liabilityData.compliancies;
    this.owners = liabilityData.owners;
    this.managements = liabilityData.managements;
    this.businessProhibitions = liabilityData.businessProhibitions;
    this.importActivities = liabilityData.importActivities;
    this.exportActivities = liabilityData.exportActivities;
    this.businessPartners = liabilityData.businessPartners;

    this.isBusinessInfoCompleted = this.businessInfo.dunsNumber &&
      this.businessInfo.tradenames?.length > 0 &&
      typeof this.businessInfo.numOfEmployees !== 'undefined' &&
      typeof this.businessInfo.annualNetSales !== 'undefined' &&
      this.businessInfo.employeeCitizenships?.length > 0;

    this.isComplianciesCompleted = this.compliancies.some(compliance =>
      compliance.type && compliance.status && compliance.lastConfirmationAt && compliance.source
    );

    this.isOwnersCompleted = this.owners.some(owner =>
      owner.type && owner.name && owner.idNumber && typeof owner.ownershipPct !== 'undefined'
    );

    this.isManagementsCompleted = this.managements.some(management =>
      management.name && management.role
    );

    this.user = liabilityData.user;
    this.operator = liabilityData.operator;

  }
};
