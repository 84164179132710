import { UserProfile, Operator, BusinessProfile, TalentProfile, Wallet, WorkShift, TaxiRide, Client, UserSubscription, LiabilityData } from '@app/core/models';

class ServiceFee {
  type?: string;
  inEur?: number;
  inPct?: number;
}

export class FinanceConfiguration { // Only editable by root-admin|root-finance
  automaticApprovalInvoiceAmount: number;
  automaticApprovalInvoiceTotalAmount: number;
  paidInvoicesRequirement: boolean;
  quickSalaryInvoicesOpenedLimit: number;
  quickSalaryInvoicesOpenedLimitInEur: number;
}

export class UserConfig { // Only editable by operator/admin, not by freelancer
  serviceFees: [ServiceFee];
  newUserRegistrationInvoicingThreshold: number;
  newUserRegistrationBonus: number;
  newUserReferrerBonus: number;
  minServiceFee: number;
  maxServiceFee: number;
  quickSalaryPaymentFeeInEur: number;
  quickSalaryPaymentFeeInPct: number;
  urgentBankTransferFeeInEur: number;
}

export class DebtCollectionConfiguration { // Only editable by operator/admin, not by freelancer. No defaults from operator config
  isEnabled: boolean;
  option: string;
  reference: string;
  amount: number;
}

export class UserSettings { // Can be edited by freelancer
  notifyNewMessages: boolean;
  notifySystemMessages: boolean;
  notifyInvoiceChanges: boolean;
  notifyPayoutPaid: boolean;
  notifySettlementPaid: boolean;
  openForJobOffers: boolean;
  dueInDaysForBusinessClients: number;
  dueInDaysForBusinessAbroadClients: number;
  dueInDaysForPersonalClients: number;
  dueInDaysForNonProfitClients: number;
  automaticPayout: string;
}

export class UserAttachments {
  fileId: string;
  description?: string;
  uploadDate: Date;
}

export class User {
  _id?: string;
  uuid?: string;
  createdAt?: Date;
  username?: string;
  role?: string;
  idNumber?: number;
  email?: string;
  createdByUser?: User;
  fullName?: string;
  publicId?: string;
  plusIdToken?: string;
  serviceProviderPlusIdToken?: string;
  plusIdConnectedAt?: Date;
  externalId?: string;

  operatorNotes?: string;
  referralCode?: string;
  signupReferralCode?: string;
  signupCampaignCode?: string;
  registrationSource?: string;

  userProfile?: UserProfile;
  operator?: Operator;
  businessProfile?: BusinessProfile;
  talentProfile?: TalentProfile;
  wallets?: [Wallet];
  parentUsers?: [User];

  configuration?: UserConfig;
  overwriteOperatorSettings?: boolean;
  financeConfiguration?: FinanceConfiguration;
  debtCollectionConfiguration?: DebtCollectionConfiguration;

  emailVerified?: boolean;

  status?: string;
  tags?: [string];
  history?: [any];
  additionalData?: any;

  unreadMessages?: number;
  activeWorkNotice?: boolean;
  unreadTransactions?: number;
  totalInvoicesNet?: number;
  totalSalaryPayoutCurrentYear?: number;
  totalSalaryPayoutThisTaxCard?: number;
  totalSalarySettlementCurrentYear?: number; // needed?
  totalSalarySettlementThisTaxCard?: number; // needed?
  validTaxRate?: number;
  totalInvoices?: number;
  totalAmountInvoiceNet?: number;

  userSettings?: UserSettings;

  userAttachments?: [UserAttachments];

  // Extra roles groups with abbreviation
  isRoot?: boolean;
  isFinance?: boolean;
  isOperator?: boolean;
  isAdmin?: boolean;
  isAccounting?: boolean;
  isNonAdmin?: boolean;
  isFreelancer?: boolean;
  isBusiness?: boolean;
  isSoletrader?: boolean;
  isCompany?: boolean;
  isTaxi?: boolean;
  isTaxiUser?: boolean;
  isTaxiAdmin?: boolean;

  // additional UI specific helpers data, sent by BE
  workShift?: WorkShift;
  taxiRide?: TaxiRide;
  linkedToClient?: Client;
  liabilityData?: LiabilityData;
  userSubscriptions?: UserSubscription[];

  /**
   * Constructor
   *
   * @param user
   */
  constructor(user?) {
    this._id = user._id || '';
    this.uuid = user.uuid || '';
    this.createdAt = user.createdAt;
    this.username = user.username || '';
    this.role = user.role || 'freelancer';
    this.idNumber = user.idNumber;
    this.email = user.email || '';
    this.plusIdToken = user.plusIdToken;
    this.serviceProviderPlusIdToken = user.serviceProviderPlusIdToken;
    this.plusIdConnectedAt = user.plusIdConnectedAt;
    this.publicId = user.publicId || '';
    this.externalId = user.externalId || null;
    this.operatorNotes = user.operatorNotes || '';
    this.referralCode = user.referralCode;
    this.signupReferralCode = user.signupReferralCode;
    this.signupCampaignCode = user.signupCampaignCode;
    this.registrationSource = user.registrationSource;
    this.createdByUser = user.createdByUser;
    this.fullName = user.fullName || '';
    this.userProfile = user.userProfile || {};
    this.operator = user.operator || {};
    this.businessProfile = user.businessProfile || {
      businessRegistration: {},
      companyTaxInfo: {}
    };
    this.talentProfile = user.talentProfile || {};
    this.wallets = user.wallets || [];
    this.parentUsers = user.parentUsers || [];
    this.emailVerified = user.emailVerified || false;
    this.status = user.status || 'enabled';
    this.tags = user.tags || [];
    this.history = user.history || [];
    this.additionalData = user.additionalData;
    this.unreadMessages = user.unreadMessages || 0;
    this.activeWorkNotice = user.activeWorkNotice || false;
    this.unreadTransactions = user.unreadTransactions || 0;
    this.totalInvoicesNet = user.totalInvoicesNet || 0;
    this.totalSalaryPayoutCurrentYear = user.totalSalaryPayoutCurrentYear || 0;
    this.totalSalaryPayoutThisTaxCard = user.totalSalaryPayoutThisTaxCard || 0;
    this.totalSalarySettlementCurrentYear = user.totalSalarySettlementCurrentYear || 0; // needed?
    this.totalSalarySettlementThisTaxCard = user.totalSalarySettlementThisTaxCard || 0; // needed?
    this.validTaxRate = user.validTaxRate;
    this.totalInvoices = user.totalInvoices || 0;
    this.totalAmountInvoiceNet = user.totalAmountInvoiceNet || 0;
    this.configuration = user.configuration || {
      serviceFees: [
        { type: 'email', inEur: 0, inPct: 0 },
        { type: 'ovt', inEur: 0, inPct: 0 },
        { type: 'paper', inEur: 0, inPct: 0 }
      ],
      minServiceFee: 0,
      maxServiceFee: 100000,
      quickSalaryPaymentFeeInEur: 0,
      quickSalaryPaymentFeeInPct: 5,
      urgentBankTransferFeeInEur: 0,
      newUserRegistrationInvoicingThreshold: 0,
      newUserRegistrationBonus: 0,
      newUserReferrerBonus: 0
    };
    this.financeConfiguration = user.financeConfiguration || {
      automaticApprovalInvoiceAmount: 0,
      automaticApprovalInvoiceTotalAmount: 0,
      paidInvoicesRequired: true,
      quickSalaryInvoicesOpenedLimit: 10,
      quickSalaryInvoicesOpenedLimitInEur: 5000
    };
    this.debtCollectionConfiguration = user.debtCollectionConfiguration || {
      isEnabled: false,
      option: 'from-net-income',
      reference: '',
      amount: 0
    };
    this.overwriteOperatorSettings = user.overwriteOperatorSettings || false;
    this.userSettings = user.userSettings || {
      notifyNewMessages: true,
      notifySystemMessages: true,
      notifyInvoiceChanges: true,
      notifyPayoutPaid: true,
      notifySettlementPaid: true,
      openForJobOffers: true,
      dueInDaysForBusinessClients: 7,
      dueInDaysForBusinessAbroadClients: 7,
      dueInDaysForPersonalClients: 14,
      dueInDaysForNonProfitClients: 7,
      automaticPayout: 'immediately'
    };
    this.userAttachments = user.userAttachments;

    // additional helpers for UI
    this.workShift = user.workShift;
    this.taxiRide = user.taxiRide;
    this.linkedToClient = user.linkedToClient;
    this.userSubscriptions = user.userSubscriptions;
    this.liabilityData = user.liabilityData;
  }

  static isRoot(role: string): boolean {
    return role && role.includes('root');
  }

  static isFinance(role: string): boolean {
    return role === 'root-finance';
  }

  static isOperator(role: string): boolean {
    return role && role.includes('operator');
  }

  static isAdmin(role: string): boolean {
    return User.isRoot(role) || User.isOperator(role);
  }

  static isAccounting(role: string): boolean {
    return role === 'operator-accounting';
  }

  static isNonAdmin(role: string): boolean {
    return !User.isAdmin(role);
  }

  static isFreelancer(role: string): boolean {
    return role === 'freelancer';
  }

  static isBusiness(role: string): boolean {
    return ['soletrader', 'company', 'taxi-company'].includes(role);
  }

  static isSoletrader(role: string): boolean {
    return role === 'soletrader';
  }

  static isCompany(role: string): boolean {
    return ['company', 'taxi-company'].includes(role);
  }

  static isTaxi(role: string): boolean {
    return role === 'taxi';
  }

  static isTaxiUser(role: string): boolean {
    return ['taxi-driver', 'taxi-company'].includes(role)
  }

  static isTaxiAdmin(role: string): boolean {
    return ['taxi-group-admin'].includes(role);
  }

  // eof
}
