// enable Bugsnag
import { environment } from '@env/environment';

import Bugsnag from '@bugsnag/js'
import { BugsnagErrorHandler } from '@bugsnag/plugin-angular'
if (environment.bugsnagEnabled && environment.bugsnagApiKey) {
  Bugsnag.start({
    apiKey: environment.bugsnagApiKey,
    appVersion: environment.appVersion,
    releaseStage: environment.name,
    enabledReleaseStages: ['staging', 'production']
  });
} else {
  console.log('AppModule/Bugsnag disabled!');
}

import { NgModule, ErrorHandler } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AppComponent } from 'app/app.component';
import { CoreModule } from 'app/core/core.module';
import { AuthGuard, FeatureGuard } from '@app/core/guards';
import { LocaleModule } from 'app/locale.module';

/* Third party modules */
import { Angulartics2Module } from 'angulartics2';

const appRoutes: Routes = [
  {
    path: 'auth',
    loadChildren: () => import('./main/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'clients',
    loadChildren: () => import('./main/clients/clients.module').then(m => m.ClientsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'invoices',
    loadChildren: () => import('./main/invoices/invoices.module').then(m => m.InvoicesModule),
    canActivate: [AuthGuard],
    data: {
      refreshList: 1
    }
  },
  {
    path: 'incomes',
    loadChildren: () => import('./main/incomes/incomes.module').then(m => m.IncomesModule),
    canActivate: [AuthGuard],
    data: {
      refreshList: 1
    }
  },
  {
    path: 'expenses',
    loadChildren: () => import('./main/expenses/expenses.module').then(m => m.ExpensesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'expenditures',
    loadChildren: () => import('./main/expenditures/expenditures.module').then(m => m.ExpendituresModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'payouts',
    loadChildren: () => import('./main/payouts/payouts.module').then(m => m.PayoutsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settlements',
    loadChildren: () => import('./main/settlements/settlements.module').then(m => m.SettlementsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'business-statements',
    loadChildren: () => import('./main/business-statements/business-statements.module').then(m => m.BusinessStatementsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'salary-calculator',
    loadChildren: () => import('./main/salary-calculator/salary-calculator.module').then(m => m.SalaryCalculatorModule)
  },
  {
    path: 'settlement-calculator',
    loadChildren: () => import('./main/settlement-calculator/settlement-calculator.module').then(m => m.SettlementCalculatorModule)
  },
  {
    path: 'bank-transfer-transactions',
    loadChildren: () => import('./main/bank-transfer-transactions/bank-transfer-transactions.module').then(m => m.BankTransferTransactionsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'public',
    loadChildren: () => import('./main/salary-calculator/salary-calculator.module').then(m => m.SalaryCalculatorModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./main/jobs/jobs.module').then(m => m.JobsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'work-shifts',
    loadChildren: () => import('./main/work-shifts/work-shifts.module').then(m => m.WorkShiftsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'work-notices',
    loadChildren: () => import('./main/work-notices/work-notices.module').then(m => m.WorkNoticesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'wallets',
    loadChildren: () => import('./main/wallets/wallets.module').then(m => m.WalletsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'insurances',
    loadChildren: () => import('./main/insurances/insurances.module').then(m => m.InsurancesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'yel-calculator',
    loadChildren: () => import('./main/yel-calculator/yel-calculator.module').then(m => m.YelCalculatorModule)
  },
  {
    path: 'faq',
    loadChildren: () => import('./main/faq/faq.module').then(m => m.FaqModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./main/users/users.module').then(m => m.UsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sub-users',
    loadChildren: () => import('./main/sub-users/sub-users.module').then(m => m.SubUsersModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'vehicles',
    loadChildren: () => import('./main/vehicles/vehicles.module').then(m => m.VehiclesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pages',
    loadChildren: () => import('./main/pages/pages.module').then(m => m.PagesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./main/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('./main/chat/chat.module').then(m => m.ChatModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'account',
    loadChildren: () => import('./main/account/account.module').then(m => m.AccountModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'operators',
    loadChildren: () => import('./main/operators/operators.module').then(m => m.OperatorsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tax-cards',
    loadChildren: () => import('./main/tax-cards/tax-cards.module').then(m => m.TaxCardsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'documentation',
    loadChildren: () => import('./main/documentation/documentation.module').then(m => m.DocumentationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'talent-search',
    loadChildren: () => import('./main/talent-search/talent-search.module').then(m => m.TalentSearchModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'terms',
    loadChildren: () => import('./main/terms/terms.module').then(m => m.TermsModule)
  },
  {
    path: 'privacy',
    loadChildren: () => import('./main/privacy/privacy.module').then(m => m.PrivacyModule)
  },
  {
    path: 'reports',
    loadChildren: () => import('./main/reports/reports.module').then(m => m.ReportsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'marketing',
    loadChildren: () => import('./main/marketing/marketing.module').then(m => m.MarketingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'map',
    loadChildren: () => import('./main/map/map.module').then(m => m.MapModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'work-hours',
    loadChildren: () => import('./main/work-hours/work-hours.module').then(m => m.WorkHoursModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./main/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule),
    canActivate: [AuthGuard, FeatureGuard]
  },
  {
    path: 'adminbot',
    loadChildren: () => import('./main/adminbot/adminbot.module').then(m => m.AdminbotModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'import-data',
    loadChildren: () => import('./main/import-data/import-data.module').then(m => m.ImportDataModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'taxi-rides',
    loadChildren: () => import('./main/taxi-rides/taxi-rides.module').then(m => m.TaxiRidesModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'taxi-contracts',
    loadChildren: () => import('./main/taxi-contracts/taxi-contracts.module').then(m => m.TaxiContractsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'taxi-contract-items',
    loadChildren: () => import('./main/taxi-contract-items/taxi-contract-items.module').then(m => m.TaxiContractItemsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'user-subscriptions',
    loadChildren: () => import('./main/user-subscriptions/user-subscriptions.module').then(m => m.UserSubscriptionsModule),
    canActivate: [AuthGuard, FeatureGuard]
  },
  {
    path: 'user-fees',
    loadChildren: () => import('./main/user-fees/user-fees.module').then(m => m.UserFeesModule),
    canActivate: [AuthGuard, FeatureGuard]
  },
  {
    path: 'liability-data',
    loadChildren: () => import('./main/liability-data/liability-data.module').then(m => m.LiabilityDataModule),
    canActivate: [AuthGuard, FeatureGuard]
  },
  {
    path: 'accounting-events',
    loadChildren: () => import('./main/accounting-events/accounting-events.module').then(m => m.AccountingEventsModule),
    canActivate: [AuthGuard]
  },
  /*{
      path        : 'ui',
      loadChildren: () => import('./main/ui/ui.module').then(m => m.UIModule)
  },*/
  {
    path: '**',
    redirectTo: 'dashboard',
    pathMatch: 'full'
  }
];

export function errorHandlerFactory() {
  if (environment.bugsnagEnabled) {
    return new BugsnagErrorHandler();
  } else {
    return new ErrorHandler();
  }
}
@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    RouterModule.forRoot(appRoutes, { enableTracing: (environment.name === 'local') }),
    CoreModule.forRoot(),
    LocaleModule,
    Angulartics2Module.forRoot({ pageTracking: { clearIds: true, clearQueryParams: true } })
  ],
  bootstrap: [
    AppComponent
  ],
  providers: [
    { provide: ErrorHandler, useFactory: errorHandlerFactory }
  ]
})
export class AppModule { }
