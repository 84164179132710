import { HistoryItem } from "./business-statement.interface";
import { Operator, User } from "@app/core/models";

type BusinessRegistryType = 'commercial' | 'prepayment' | 'employer' | 'vat';

export interface BusinessRegistry {
  type: BusinessRegistryType;
  status: string;
  registeredAt: Date;
  registeredYear: number;
  authority: string;
  registryData: string;
};

interface BusinessInfo {
  dunsNumber: string;
  businessRegistries: [BusinessRegistry];
  tradenames: [string];
  annualNetSales: number;
  numOfEmployees: number;
  shareCapital: number;
  numOfShares: number;
  employeeCitizenships: [string];
  employeeTitles: [string];
}


export interface Compliance {
  type: string;
  status: string;
  lastConfirmationAt: Date;
  source: string;
}

export interface Owner {
  type: string;
  name: string;
  idNumber: string;
  ownershipPct: number;
}

export interface Management {
  name: string;
  role: string;
}

export interface LiabilityDataInsurance {
  type: string;
  providerName: string;
  details: string;
  validFrom: Date;
  validTo: Date;
  lastPaymentAt: Date;
  source: string;
}

export interface CollectiveAgreement {
  name: string;
  details: string;
}

export interface BusinessProhibition {
  name: string;
  reason: string;
  validFrom: Date;
  validTo: Date;
}

export interface Activities {
  status: string;
  countries: [string];
}

type TypeOfPartnership = 'client' | 'supplier' | 'partner';

export interface BusinessPartner {
  name: string;
  companyId: string;
  country: string;
  typeOfPartnership: TypeOfPartnership
}

export class LiabilityData {
  _id: string;

  lastConfirmedByUserAt: Date;

  businessInfo: BusinessInfo;
  compliancies: [Compliance];
  owners: [Owner];
  managements: [Management];
  insurances: [LiabilityDataInsurance];
  collectiveAgreements: [CollectiveAgreement];
  businessProhibitions: [BusinessProhibition];
  importActivities: Activities;
  exportActivities: Activities;
  businessPartners: [BusinessPartner];

  isBusinessInfoCompleted: boolean;
  isComplianciesCompleted: boolean;
  isOwnersCompleted: boolean;
  isManagementsCompleted: boolean;
  isInsurancesCompleted: boolean;

  history: [HistoryItem];

  /* Model references */
  user: User;
  operator: Operator;

  constructor(liabilityData?: LiabilityData) {
    this._id = liabilityData._id;
    this.lastConfirmedByUserAt = liabilityData.lastConfirmedByUserAt;

    this.businessInfo = liabilityData.businessInfo;
    this.compliancies = liabilityData.compliancies;
    this.owners = liabilityData.owners;
    this.managements = liabilityData.managements;
    this.insurances = liabilityData.insurances;
    this.collectiveAgreements = liabilityData.collectiveAgreements;
    this.businessProhibitions = liabilityData.businessProhibitions;
    this.importActivities = liabilityData.importActivities;
    this.exportActivities = liabilityData.exportActivities;
    this.businessPartners = liabilityData.businessPartners;

    this.isBusinessInfoCompleted = this.businessInfo.dunsNumber &&
      this.businessInfo.tradenames?.length > 0 &&
      this.businessInfo.businessRegistries?.length > 0 &&
      typeof this.businessInfo.numOfEmployees !== 'undefined' &&
      typeof this.businessInfo.annualNetSales !== 'undefined' &&
      this.businessInfo.employeeCitizenships?.length > 0;

    this.isComplianciesCompleted = this.compliancies.some(compliance =>
      compliance.type && compliance.status && compliance.lastConfirmationAt && compliance.source
    );

    this.isOwnersCompleted = this.owners.some(owner =>
      owner.type && owner.name && owner.idNumber && typeof owner.ownershipPct !== 'undefined'
    );

    this.isManagementsCompleted = this.managements.some(management =>
      management.name && management.role
    );

    this.isInsurancesCompleted = this.insurances.some(insurance =>
      insurance.details && insurance.lastPaymentAt && insurance.providerName && insurance.source &&
      insurance.type && insurance.validFrom && insurance.validTo
    );

    this.user = liabilityData.user;
    this.operator = liabilityData.operator;

  }
};
