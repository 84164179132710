import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable, Subject, lastValueFrom } from 'rxjs';
import { User, Operator } from '../models';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FiltersService {
  private user = new BehaviorSubject<string>(undefined);
  private operator = new BehaviorSubject<string>(undefined);
  public userData = new BehaviorSubject<User>(undefined);
  public operatorData = new BehaviorSubject<Operator>(undefined);

  selectedUser$ = new Subject<boolean>();
  selectedUserText$ = new Subject<string>();

  inputSelectedOperatorText = new Subject<string>();
  newinputSelectedOperatorText = this.inputSelectedOperatorText.asObservable();
  selectedOperator = new Subject<boolean>();
  newSelectedOperatorstate = this.selectedOperator.asObservable();

  private prevUser: string;

  constructor(
    private apiService: ApiService
  ) { }

  onUserChanged(): Observable<string> {
    return this.user.asObservable();
  }

  onOperatorChanged(): Observable<string> {
    return this.operator.asObservable();
  }

  get userValue(): string {
    return this.user.value;
  }

  get operatorValue(): string {
    return this.operator.value;
  }

  setUser(userId: string) {
    this.user.next(userId);
  }

  setOperator(operatorId: string) {
    console.log('setOperator()', operatorId);
    this.operator.next(operatorId);
  }

  remove() {
    this.user.next(null);
  }

  removeOperator() {
    this.operator.next(null);
  }

  get selectedUser(): Observable<boolean> {
    return this.selectedUser$.asObservable();
  }

  get selectedUserText(): Observable<string> {
    return this.selectedUserText$.asObservable();
  }

  /* To be refactored */
  setSelectedUserStatus(status: boolean) {
    this.selectedUser$.next(status);
  }

  /* To be refactored */
  setOperatorInput(inputText: string) {
    this.inputSelectedOperatorText.next(inputText);
  }

  /* To be refactored */
  setSelectedOperatorStatus(status: boolean) {
    this.selectedOperator.next(status);
  }

  async selectUser(userId: string, role: string, storePrevUser = false) {
    console.log('selectUser() userId:', userId);

    if (storePrevUser) {
      this.prevUser = this.userValue;
      console.log('selectUser() store previous user; prevUser:', this.prevUser);
    }

    if (userId) {
      const user = await lastValueFrom(this.apiService.getUser(userId));
      this.getSelectedUserInfo(user, user._id.toString(), role);

      this.setSelectedUserStatus(true);
    }
  }

  getSelectedUserInfo(userSelected: User, userId: string, currentUserRole: string = null) {
    console.log('getSelectedUserInfo()');
    console.log('getSelectedUserInfo() userSelected:', userSelected);

    const publicId = userSelected.publicId ? ' · ' + userSelected.publicId : '';
    const op = (['root-admin', 'root-support'].includes(currentUserRole)) ? ' · ' + userSelected.operator.shortName : publicId;
    console.log('getSelectedUserInfo() set value to user selector:', userSelected.userProfile.fullName + op);
    this.selectedUserText$.next(userSelected.userProfile.fullName + op);

    this.setUser(userId);
    this.userData.next(userSelected);
  }

  clearUser(returnPrevUser = false, role: string) {
    console.log('clearUser()');

    if (returnPrevUser && this.prevUser) {
      console.log('clearUser() restore previous user..');
      this.selectUser(this.prevUser, role);
    } else {
      this.setSelectedUserStatus(false);
      this.selectedUserText$.next('');

      this.setUser(undefined);
      this.userData.next(undefined);
    }
  }

}
