import { TaxCard } from '@app/core/models';

export class BusinessRegistration {
  isOldBusinessRegistration?: boolean;
  hasUserConfirmedSuomiFiForRegistration?: boolean;
  isRegisteredForCompanyId?: boolean;
  isRegisteredToCommercialRegistry?: boolean;
  isRegisteredToPreTaxRegistry?: boolean;
  isRegisteredToVatRegistry?: boolean;
  exclusionOfVATJustifications?: string;
  registriesConfirmed?: boolean;
  yehRegistrationTransactionUuid?: string;
  yehRegistrationDocuments?: [string]
};

export class BusinessProfile {
  _id?: string;
  createdAt?: Date;
  updatedAt?: Date;

  companyId?: string;
  companyVatId?: string;

  businessName?: string;
  businessDescription?: string;
  businessSectorName?: string;
  businessSectorCode?: string;

  streetAddress1?: string;
  streetAddress2?: string;
  postalCode?: string;
  city?: string;
  municipalityCode?: string;
  stateOrProvince?: string;
  countryCode?: string;
  formattedAddress?: string;
  fullAddress?: string;

  professionName?: string;
  professionCode?: string;

  bankAccountIban?: string;
  bankAccountBic?: string;
  bankAccountInformation?: string;
  bankAccountOwner?: string;

  businessKeywords?: [string];

  yelStatus?: string;

  isCompleted?: boolean;
  isBankInfoCompleted?: boolean;

  taxCard?: TaxCard;
  salaryYelMonthlyReduction?: number;

  // soletrader and company specific fields
  businessYearlyIncome?: number;
  businessOtherIncome?: number;
  businessTaxRate?: number;
  accidentInsuranceStatus?: string;
  // businessTaxRateThreshold?: number;
  businessRegistration?: BusinessRegistration;
  companyTaxInfo?: any;

  additionalData?: any;

  /**
   * Constructor
   *
   * @param businessProfile
   */
  constructor(businessProfile?) {
    this._id = businessProfile._id || '';
    this.createdAt = businessProfile.createdAt;
    this.updatedAt = businessProfile.updatedAt;
    this.companyId = businessProfile.companyId || '';
    this.companyVatId = businessProfile.companyVatId || '';
    this.businessName = businessProfile.businessName || '';
    this.businessDescription = businessProfile.businessDescription || '';
    this.businessSectorName = businessProfile.businessSectorName || '';
    this.businessSectorCode = businessProfile.businessSectorCode || '';
    this.streetAddress1 = businessProfile.streetAddress1;
    this.streetAddress2 = businessProfile.streetAddress2;
    this.postalCode = businessProfile.postalCode;
    this.city = businessProfile.city;
    this.stateOrProvince = businessProfile.stateOrProvince;
    this.countryCode = businessProfile.countryCode || 'FI';
    this.formattedAddress = businessProfile.formattedAddress;
    this.fullAddress = businessProfile.fullAddress;
    this.professionName = businessProfile.professionName || '';
    this.professionCode = businessProfile.professionCode || '';
    this.bankAccountIban = businessProfile.bankAccountIban || '';
    this.bankAccountBic = businessProfile.bankAccountBic || '';
    this.bankAccountInformation = businessProfile.bankAccountInformation || '';
    this.bankAccountOwner = businessProfile.bankAccountOwner || '';
    this.businessKeywords = businessProfile.businessKeywords || [];
    this.isCompleted = businessProfile.isCompleted;
    this.isBankInfoCompleted = businessProfile.isBankInfoCompleted;
    this.taxCard = businessProfile.taxCard || {};
    this.yelStatus = businessProfile.yelStatus || 'low-income';
    this.salaryYelMonthlyReduction = businessProfile.salaryYelMonthlyReduction || 0;
    this.businessYearlyIncome = businessProfile.businessYearlyIncome || 0;
    this.businessOtherIncome = businessProfile.businessOtherIncome || 0;
    this.businessTaxRate = businessProfile.businessTaxRate || 0;
    this.accidentInsuranceStatus = businessProfile.accidentInsuranceStatus || '';
    // this.businessTaxRateThreshold = businessProfile.businessTaxRateThreshold || 0;
    this.businessRegistration = businessProfile.businessRegistration || {};
    this.companyTaxInfo = businessProfile.companyTaxInfo || {};
    this.additionalData = businessProfile.additionalData || {};
  }
}
