import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { Router } from '@angular/router';

import { catchError } from 'rxjs/operators';
import { AuthenticationService, ToasterService, CurrentUserService } from '@app/core/services';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authenticationService: AuthenticationService,
    private router: Router,
    private currentUserService: CurrentUserService,
    private toasterService: ToasterService
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const user = this.currentUserService.getUser();
    const started = Date.now();

    return next.handle(request).pipe(
      catchError(err => {
        AuthenticationService
        if (this.authenticationService.isAuthenticated()) {
          if (err.status === 401) {
            console.log('ErrorInterceptor.catchError: 401');
            this.authenticationService.removeLoggedUser();
            this.router.navigate(['/auth/login']);
            setTimeout(_ => {
              console.log('ErrorInterceptor.catchError: authenticated, but no access..');
              this.toasterService.showToaster('login.tokenExpired', { duration: 'long', type: 'info' });
            }, 500);
          } else if (err.status === 403) {
            console.log('ErrorInterceptor.catchError: 403');
            this.router.navigate(['/dashboard/' + user.role], { state: { insufficientPermissions: true } });
          } else {
            console.log('ErrorInterceptor.catchError: other error: ', JSON.stringify(err));
          }
        } else {
          console.log('ErrorInterceptor.catchError: user not authenticated!');
          // console.log('ErrorInterceptor.catchError: this.router.url:', this.router.url);

          if (this.router.url.includes('client-review') || this.router.url.includes('register')) {
            console.log('ErrorInterceptor.catchError: stay in the page to try again..');
          } else {
            console.log('ErrorInterceptor.catchError: remove logged user and go to login');
            this.authenticationService.removeLoggedUser();
            this.router.navigate(['/auth/login']);
          }
          // setTimeout(_ =>  {
          //     this.toasterService.showToaster('login.sessionExpired', { duration: 'long', type: 'warning' });
          // }, 500)
        }

        const error = err.error || err.statusText;
        return throwError(error);
      })
    );
  }
}
