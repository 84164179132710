import { User } from './user.model';
import { TaxiVehicle } from './taxi-vehicle.model';
import { Operator } from './operator.model';
import { Client } from './client.model';
import { Income } from './income.model';
import { Invoice } from './invoice.model';
import { WorkShift } from './work-shift.model';

export interface Payment {
  paymentMethod: string;
  gross: number;
  client?: string;
  additionalData?: object;
}

export class TaxiRide {
  _id?: string;
  status?: string;
  taxiRideNumber?: number;
  uuid?: string;
  publicId?: string;
  description?: string;
  bookedAt?: Date;
  pickupAt?: Date;
  dropOffAt?: Date;
  fromAddress?: string;
  toAddress?: string;
  fromCoordinates?: { latitude: number; longitude: number };
  toCoordinates?: { latitude: number; longitude: number };
  distanceInKm?: number;
  luggage?: number;
  rideType?: string;
  source?: string;
  totalNet?: number;
  totalGross?: number;
  feesIncludedInTotalGross?: number;
  feesIncludedInTotalDescription?: string;
  vatClass?: string;
  totalVat?: number;
  payments?: [Payment];
  tags?: string[];
  breakTimes?: [object];
  vehicleStartKm?: number;
  vehicleEndKm?: number;
  additionalData?: any;
  history?: {
    time?: Date;
    status?: string;
    event?: string;
    description?: string;
    userId?: User | string;
  }[];
  // new payment meta variables in UI only
  newPaymentGross?: number;
  newPaymentPaymentMethod?: string;
  newPaymentClient?: Client | string;
  // references: object or objectId/string
  taxiCompanyUser?: User | string;
  taxiDriverUser?: User | string;
  taxiVehicle?: TaxiVehicle | string;
  operator?: Operator | string;
  // client?: Client | string;
  income?: Income | string;
  invoice?: Invoice | string;
  workShift?: WorkShift | string;

  // Virtual properties
  totalGrossDuration?: number;
  totalBreakTimesDuration?: number;
  totalNetDuration?: number;

  /**
   * Constructor
   *
   * @param data: TaxiRide
   */
  constructor(data?: any) {
    this._id = data?._id;
    this.status = data?.status || 'created';
    this.taxiRideNumber = data?.taxiRideNumber;
    this.uuid = data?.uuid;
    this.publicId = data?.publicId;
    this.description = data?.description || '';
    this.bookedAt = data?.bookedAt ? new Date(data.bookedAt) : undefined;
    this.pickupAt = data?.pickupAt ? new Date(data.pickupAt) : undefined;
    this.dropOffAt = data?.dropOffAt ? new Date(data.dropOffAt) : undefined;
    this.fromAddress = data?.fromAddress || '';
    this.toAddress = data?.toAddress || '';
    this.fromCoordinates = data?.fromCoordinates || { latitude: 0, longitude: 0 };
    this.toCoordinates = data?.toCoordinates || { latitude: 0, longitude: 0 };
    this.distanceInKm = data?.distanceInKm || 0;
    this.luggage = data?.luggage || 0;
    this.rideType = data?.rideType || 'standard';
    this.source = data?.source || '';
    this.totalNet = data?.totalNet || 0;
    this.totalGross = data?.totalGross || 0;
    this.feesIncludedInTotalGross = data?.feesIncludedInTotalGross || 0;
    this.feesIncludedInTotalDescription = data?.feesIncludedInTotalDescription || '';
    this.vatClass = data?.vatClass || 'vat10';
    this.totalVat = data?.totalVat || 0;
    this.payments = data?.payments || [];
    this.tags = data?.tags || [];
    this.breakTimes = data?.breakTimes || [];
    this.vehicleStartKm = data?.vehicleStartKm || 0;
    this.vehicleEndKm = data?.vehicleEndKm || 0;
    this.additionalData = data?.additionalData || {};
    this.history = data?.history || [];
    this.taxiCompanyUser = data?.taxiCompanyUser?._id ? new User(data.taxiCompanyUser) : data?.taxiCompanyUser;
    this.taxiDriverUser = data?.taxiDriverUser?._id ? new User(data.taxiDriverUser) : data?.taxiDriverUser;
    this.taxiVehicle = data?.taxiVehicle?._id ? new TaxiVehicle(data.taxiVehicle) : data?.taxiVehicle;
    this.operator = data?.operator?._id ? new Operator(data.operator) : data?.operator;
    // this.client = data?.client?._id ? new Client(data.client) : data?.client;
    this.income = data?.income?._id ? new Income(data.income) : data?.income;
    this.invoice = data?.invoice?._id ? new Invoice(data.invoice) : data?.invoice;
    this.workShift = data?.workShift?._id ? new WorkShift(data.workShift) : data?.workShift;
  }
}

