import { User, Operator, Payout, Settlement, Invoice, CreditCheck, Message } from '@app/core/models';

export class OperatorFee {
  _id?: string;
  amount: number;
  type: OperatorFeeType;
  feeDate: Date;
  operator: Operator;
  payout?: Payout;
  settlement?: Settlement;
  invoice?: Invoice;
  creditCheck?: CreditCheck;
  message?: Message;
  v1ApiCall?: object;
  v1IncomeTaxData?: any; // Replace 'any' with the appropriate type when available
  v1IncomeReport?: any; // Replace 'any' with the appropriate type when available
  v1SalaryCalculation?: any; // Replace 'any' with the appropriate type when available
  v1Invoice?: any; // Replace 'any' with the appropriate type when available
  v1InvoiceFinance?: any; // Replace 'any' with the appropriate type when available
  v1ApiRequest?: any; // Replace 'any' with the appropriate type when available
  createdAt?: Date;
  updatedAt?: Date;

  constructor(data: Partial<OperatorFee>) {
    Object.assign(this, data);
    // this.validateAmount();
    // this.validateFeeDate();
  }

  // private validateAmount(): void {
  //   if (this.amount === undefined || this.amount < 0.01) {
  //     console.error('OperatorFee.constructor(): Invalid amount', this.amount);
  //     throw new Error('Amount must be at least 0.01');
  //   }
  // }

  // private validateFeeDate(): void {
  //   if (!this.feeDate) {
  //     console.error('OperatorFee.constructor(): Missing feeDate');
  //     throw new Error('Fee date is required');
  //   }
  // }
}

export enum OperatorFeeType {
  CreditCheck = 'credit-check',
  DebtCollection = 'debt-collection',
  QuickSalary = 'quick-salary',
  ServiceFee = 'service-fee',
  SalaryPayout = 'salary-payout',
  SMS = 'sms',
  WorkHour = 'work-hour',
  MonthlyIdVerification = 'monthly-id-verification',
  MonthlyAiInvoicing = 'monthly-ai-invoicing',
  MonthlyAiChatbot = 'monthly-ai-chatbot',
  ApiVeroTaxcard = 'api-vero-taxcard',
  ApiVeroIncomereport = 'api-vero-incomereport',
  ApiSalaryCalc = 'api-salary-calc',
  ApiInvoice = 'api-invoice',
  ApiInvoiceFinance = 'api-invoice-finance',
  V1apiData = 'v1api-data'
}
