import { Invoice } from '@app/core/models'
import { Payout } from './payout.model';
import { Settlement } from './settlement.model';
import { User } from './user.model';

export class Expense {
  _id?: string;
  createdAt?: Date;
  uuid?: string;
  expenseNumber?: number;
  type?: string;
  status?: string;
  quantity?: number;
  netPrice?: number;
  vatRate?: number;
  netPriceTaxFree?: number;
  currency?: string;
  reference?: string;
  description?: string;
  tags?: string[];
  additionalData?: any;
  ownedByUser?: string;
  isPayoutDeduction?: boolean;
  deductionType?: string;
  user?: User;
  invoice?: Invoice;
  payout?: Payout;
  settlement?: Settlement;

  // Receipts
  expenseDate?: Date;
  fileId?: string | null;

  // Other expenses
  taxType?: string;

  // Travel kilometers
  travelFrom?: any;
  travelTo?: any;
  isBackAndForth?: boolean;
  travelBy?: string;
  licensePlate?: string;
  reason?: string;
  waypoints?: any;
  isExpenseWithSpecialIndustryFees?: boolean;
  skipGoogleMap?: boolean;

  // Daily allowance
  startTime?: Date;
  endTime?: Date;
  streetAddress?: string;
  countryCode?: string;

  // Meal allowance
  isTwice?: boolean;

  // Virtuals
  filePath?: string;
  vatPrice?: number;
  grossPrice?: number;
  grossPriceCalc?: number;
  totalGross?: number;
  totalNet?: number;
  totalVat?: number;
  totalNetTaxFree?: number;
  attachments?: any;

  /**
   * Constructor
   *
   * @param expense
   */
  constructor(expense?) {
    this._id = expense._id || '';
    this.createdAt = expense.createdAt || new Date();
    this.uuid = expense.uuid || '';
    this.expenseNumber = expense.expenseNumber;
    this.type = expense.type;
    this.status = expense.status;
    this.quantity = expense.quantity;
    this.vatRate = expense.vatRate;
    this.netPrice = expense.netPrice;
    this.currency = expense.currency || 'EUR';  // TODO: get default currency from operator config?
    this.reference = expense.reference || '';
    this.description = expense.description || '';
    this.tags = expense.tags || [];
    this.additionalData = expense.additionalData || {};
    this.ownedByUser = expense.ownedByUser;
    this.isPayoutDeduction = expense.isPayoutDeduction;
    this.deductionType = expense.deductionType;
    this.user = expense.user;
    this.invoice = expense.invoice;
    this.payout = expense.payout;
    this.settlement = expense.settlement;

    this.expenseDate = expense.expenseDate;
    this.fileId = expense.fileId;

    this.taxType = expense.taxType;

    this.travelFrom = expense.travelFrom;
    this.travelTo = expense.travelTo;
    this.isBackAndForth = expense.isBackAndForth;
    this.isTwice = expense.isTwice;
    this.travelBy = expense.travelBy;
    this.licensePlate = expense.licensePlate;
    this.isExpenseWithSpecialIndustryFees = expense.isExpenseWithSpecialIndustryFees;
    this.reason = expense.reason || '';
    this.waypoints = expense.waypoints || [];

    this.startTime = expense.startTime;
    this.endTime = expense.endTime;
    this.streetAddress = expense.streetAddress
    this.countryCode = expense.countryCode || 'FI';

    this.filePath = expense.filePath;
    this.vatPrice = expense.vatPrice;
    this.grossPrice = expense.grossPrice;
    this.grossPriceCalc = expense.grossPriceCalc;
    this.totalNet = expense.totalNet;
    this.totalVat = expense.totalVat;
    this.totalGross = expense.totalGross;
    this.totalNetTaxFree = expense.totalNetTaxFree;
    this.attachments = expense.attachments || [''];
    this.skipGoogleMap = expense.skipGoogleMap;
  }
}
