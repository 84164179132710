import { User, Operator, Client, Settlement } from '@app/core/models';
import { to } from '../../../../../ab-backend/tools/invoices/list_invoices_due';

export class IncomeItem {
  title: string;
  description: string;
  totalNet: number;
  totalGross: number;
  isGrossCalc: boolean;
  vatClass: string;
  // virtuals
  vatRate: number;
  totalVat: number;

  constructor(data?: any) {
    if (data) {
      this.title = data.title;
      this.description = data.description;
      this.totalNet = data.totalNet;
      this.totalGross = data.totalGross;
      this.isGrossCalc = data.isGrossCalc;
      this.vatClass = data.vatClass;
    }
  }
}

export class Income {
  _id?: string;
  uuid?: string;
  publicId?: string;
  incomeDate?: Date;
  type?: string; // e.g. 'taxi_ride'
  isForSettlement?: boolean;
  status: 'created' | 'paid' | 'cancelled' | 'refunded' | 'credit' = 'created';
  description?: string;
  incomeItems: IncomeItem[] = [];
  currency?: string;
  paymentMethod?: 'cash' | 'credit_card' | 'bank_transfer' | 'other';
  category?: string;
  tags: string[] = [];
  additionalData?: any;
  history: {
    time: Date;
    status: string;
    event?: string;
    description?: string;
  }[] = [];
  // refs
  user: User;
  operator: Operator;
  client?: Client;
  settlement?: Settlement;
  creditedIncome?: Income;
  // virtuals
  totalNet?: number;
  totalGross?: number;
  totalVat?: number;
  vatClass?: string;

  constructor(data?: any) {
    if (data) {
      this._id = data._id;
      this.uuid = data.uuid;
      this.publicId = data.publicId;
      this.incomeDate = data.incomeDate;
      this.type = data.type;
      this.isForSettlement = data.isForSettlement;
      this.status = data.status;
      this.description = data.description;
      this.incomeItems = (data.incomeItems || []).map((item) => new IncomeItem(item));
      this.currency = data.currency || 'EUR';
      this.paymentMethod = data.paymentMethod;
      this.category = data.category;
      this.tags = data.tags || [];
      this.additionalData = data.additionalData;
      this.history = data.history || [];
      // refs
      this.user = data.user;
      this.operator = data.operator;
      this.client = data.client;
      this.settlement = data.settlement;
      this.creditedIncome = data.creditedIncome;
      // virtuals
      this.totalNet = data.totalNet;
      this.totalGross = data.totalGross;
      this.totalVat = data.totalVat;
      this.vatClass = data.vatClass;
    }
  }
}

