import { User, Operator, Client } from '@app/core/models';

export class ImportData {
  _id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  uuid?: string;
  status?: string;

  // main import data
  type?: string;
  periodStartAt?: Date;
  periodEndAt?: Date;
  tags?: string;

  // File data
  fileName?: string;
  fileData?: any;
  processingResult?: any;
  processingErrors?: [any];

  // Model references
  user?: User;
  operator?: Operator;
  client?: Client;

  /**
   * Constructor
   *
   * @param transaction
   */
  constructor(importData?) {
    this._id = importData._id;
    this.createdAt = importData.createdAt || '';
    this.updatedAt = importData.updatedAt || '';
    this.uuid = importData.uuid || '';
    this.status = importData.status || 'created';
    this.type = importData.type || '';
    this.periodStartAt = importData.periodStartAt || '';
    this.periodEndAt = importData.periodEndAt || '';
    this.tags = importData.tags || '';
    this.fileName = importData.fileName || '';
    this.fileData = importData.fileData || '';
    this.processingResult = importData.processingResult || {};
    this.processingErrors = importData.processingErrors || [];
    this.user = importData.user;
    this.operator = importData.operator;
    this.client = importData.client;
  }
}
