
import { HistoryItem } from "./business-statement.interface";
import { User, Operator, Payout, Settlement, Invoice, UserSubscription } from "@app/core/models";

export interface UserFee {
  _id: string;

  type: string;
  status: string;
  title: string;
  description: string;
  feeDate: Date;
  netPrice: number;
  vatClass: string;
  currency: string;
  paidAt: string;

  user: User;
  operator: Operator;
  userSubscription: UserSubscription;
  invoice: Invoice;
  settlement: Settlement;
  payout: Payout;

  createdAt: Date;
  updatedAt?: Date;
  history: [HistoryItem];
}


