import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, delay, forkJoin, interval, map, switchMap, takeWhile } from 'rxjs';
import { environment } from '@env/environment';

import { PageEvent } from "@angular/material/paginator";
import { Sort } from '@angular/material/sort';

import { AppSettingsConfig } from '@app/core/configs/app-settings.config';
import {
  AccountingEvent,
  BankTransferTransaction,
  Campaign,
  Client,
  Expense,
  Income,
  Invoice,
  InvoiceURowItem,
  ImportData,
  LiabilityData,
  Job,
  Message,
  MultiExpense,
  Operator,
  OperatorFee,
  TaxCard,
  TaxiContract,
  TaxiContractItem,
  Subscription,
  TaxiRide,
  User,
  UserFee,
  WorkHours,
  WorkNotice,
  WorkShift,
} from '@app/core/models';

import { UserSubscription } from '../models/user-subscription.interface';
import { ExpenseConfig } from '@app/shared/helpers/expense-helper';
import { AIReceiptCreationResponse } from '@app/main/multi-expenses/multi-expenses-form/multi-expenses-form.component';

// set default UI client http request options
// set content type and default timeout to 10 secs
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  timeout: 10000
};
const blobHttpOptions: object = {
  headers: new HttpHeaders({ 'Content-Type': 'application/octet-stream' }),
  responseType: 'blob',
  timeout: 30000
};
const importDataHttpOptions: object = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  timeout: 60000
};

export interface MultiExpenseResponseDTO {
  multiExpense: MultiExpense;
  user: User;
  clientCustomization: any;
  recentMileage: {
    licensePlate: string,
    odometerEndKm: number
  };
}

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  private apiUrl = environment.baseApiUrl;
  private authUrl = this.apiUrl + 'user/';

  constructor(
    private http: HttpClient,
    private appConfig: AppSettingsConfig
  ) { }

  buildAuthUrl(path: string, search: string | null, filters?: any, pagination?: PageEvent, sorting?: Sort): string {
    let url = this.authUrl + path;
    if (search !== null) url += `?search=${encodeURIComponent(search)}`;
    let firstParam = search !== null ? false : true;

    if (filters && Object.keys(filters).length) {
      Object.keys(filters).forEach((field) => {
        if (filters[field]) {
          url += (firstParam ? '?' : '&') + field + '=' + filters[field];
          if (firstParam) firstParam = false;
        }
      });
    }
    if (pagination) {
      url += '&page=' + ((pagination.pageIndex || 0) + 1) + '&pageSize=' + (pagination.pageSize || 500);
    }
    if (sorting) {
      url += '&sortDir=' + (sorting.direction || 'desc') + '&sortBy=' + (sorting.active || 'createdAt');
    }

    return url;
  }

  login(credentials: any): Observable<any> {
    credentials.operator = this.appConfig?.operator?.shortName || '';
    return this.http.post<any>(this.apiUrl + 'auth/login', credentials, httpOptions);
  }

  otpLogin(otp: string, tosAccepted: boolean): Observable<any> {
    let body = { otp, tosAccepted, operator: this.appConfig.operator.shortName };
    return this.http.post<any>(this.apiUrl + 'auth/otp-login', body, httpOptions);
  }

  passwordForgot(emailOrUsername: string): Observable<any> {
    let body = { emailOrUsername, operator: this.appConfig.operator.shortName };
    return this.http.post(this.apiUrl + 'auth/password/forgot', body, httpOptions);
  }

  passwordReset(resetParams: any): Observable<any> {
    return this.http.post(this.apiUrl + 'auth/password/reset', resetParams, httpOptions);
  }

  register(registration: any): Observable<any> {
    registration.operator = this.appConfig.operator.shortName;
    return this.http.post(this.apiUrl + 'auth/register', registration, httpOptions);
  }

  changePassword(changeParams: any): Observable<any> {
    return this.http.post(this.authUrl + 'users/change-password', changeParams, httpOptions);
  }

  getBrand(): Observable<any> {
    return this.http.get(this.apiUrl + 'brand', httpOptions);
  }

  requestEmailVerification(userId: any): Observable<any> {
    return this.http.post(this.authUrl + 'users/request-email-verification', { userId }, httpOptions);
  }

  verifyEmail(token: string): Observable<any> {
    return this.http.post(this.apiUrl + 'auth/verify-email', { token }, httpOptions);
  }

  startPlusidRegistration(): Observable<any> {
    const url = this.apiUrl + 'auth/plusid/start-registration';
    return this.http.get(url, httpOptions);
  }

  startPlusIdLogin(): Observable<any> {
    return this.http.get(this.apiUrl + 'auth/plusid/start-login', httpOptions);
  }

  startPlusIdConnectLink(): Observable<any> {
    return this.http.get(this.authUrl + 'users/start-link-connect', httpOptions);
  }

  checkConnectLink(requestId: string): Observable<any> {
    const url = this.authUrl + 'users/link-connect';
    return this.http.post(url, { requestId }, httpOptions);
  }

  checkRegistrationStatus(requestId: string): Observable<any> {
    const url = this.apiUrl + 'auth/plusid/register';
    return this.http.post(url, { requestId }, httpOptions);
  }

  loginWithPlusId(connectId: string, tosAccepted: boolean): Observable<any> {
    const url = this.apiUrl + 'auth/plusid/login';
    return this.http.post(url, { connectId }, httpOptions);
  }

  postConnectIdVerification(requestId: string): Observable<any> {
    return this.http.post(this.authUrl + 'auth/plusid/start-id-verification', { requestId }, httpOptions);
  }

  /* Info */
  calculateSalaryPublicly(amount: number, taxRate: number, yelInsured: boolean): Observable<any> {
    let url = this.apiUrl + 'info/calculate-salary/' + amount + '/' + taxRate + ' /' + yelInsured;
    return this.http.get(url, httpOptions);
  }
  calculateSettlementPublicly(amountNet: number, amountGross: number): Observable<any> {
    let url = this.apiUrl + 'info/calculate-settlement/' + amountNet + '/' + amountGross;
    return this.http.get(url, httpOptions);
  }

  /* Users */
  getUser(id: string): Observable<User> {
    return this.http.get(this.authUrl + 'users/' + id, httpOptions);
  }

  getUserInfo(): Observable<User> {
    return this.http.get(this.authUrl + 'info', httpOptions);
  }

  postUser(newUser): Observable<any> {
    return this.http.post(this.authUrl + 'users', newUser, httpOptions);
  }

  updateUser(id: any, update: User): Observable<User> {
    return this.http.put(this.authUrl + 'users/' + id, update, httpOptions);
  }

  deleteUser(id: string): Observable<User> {
    return this.http.delete(this.authUrl + 'users/' + id, httpOptions);
  }

  removeDebtCollection(id: string): Observable<User> {
    return this.http.get(this.authUrl + 'users/remove-debt-collection/' + id, httpOptions);
  }

  getUsers(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('users', search, filters, pagination, sorting);
    return this.http.get<User[]>(url, httpOptions);
  }

  downloadUsersExcel(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('users/download-excel', search, filters, pagination, sorting);
    return this.http.get(url, blobHttpOptions);
  }

  // Taxi users
  getSubUsers(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('sub-users', search, filters, pagination, sorting);

    return this.http.get<User[]>(url, httpOptions);
  }

  getVehicles(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('vehicles', search, filters, pagination, sorting);

    return this.http.get<any[]>(url, httpOptions);
  }

  getAllVehicles(owners: any): Observable<any> {
    return this.http.post(this.authUrl + 'vehicles', owners, httpOptions);
  }

  getParentUsers(userId: string): Observable<any> {
    let url = this.authUrl + 'sub-users/parentUsers/' + userId;
    return this.http.get<User[]>(url, httpOptions);
  }

  getTaxiGroups(): Observable<any> {
    let url = this.authUrl + 'taxis/groups';
    return this.http.get<any[]>(url, httpOptions);
  }

  getSubUserByEmail(email): Observable<User> {
    return this.http.post(this.authUrl + 'sub-user', { email: email }, httpOptions);
  }

  getSubUserByPhone(phone): Observable<User> {
    return this.http.post(this.authUrl + 'sub-user', { phone: phone }, httpOptions);
  }

  addSubUser(addition): Observable<User> {
    return this.http.post(this.authUrl + 'sub-user/add', { addition }, httpOptions);
  }

  addVehicle(vehicle): Observable<any> {
    return this.http.post(this.authUrl + 'vehicle/add', { ...vehicle }, httpOptions);
  }

  updateVehicle(id: string, update: any): Observable<any> {
    return this.http.put(this.authUrl + 'vehicle/put/' + id, update, httpOptions);
  }

  inviteSubUser(invitation): Observable<User> {
    return this.http.post(this.authUrl + 'sub-user/invite', { invitation }, httpOptions);
  }

  removeSubUser(removeData): Observable<User> {
    return this.http.post(this.authUrl + 'sub-user/remove', { removeData }, httpOptions);
  }

  removeVehicle(vehicleId): Observable<User> {
    return this.http.delete(this.authUrl + 'vehicle/remove/' + vehicleId, httpOptions);
  }

  postUserAction(id, action): Observable<any> {
    return this.http.post(this.authUrl + 'users/' + id, { name: action }, httpOptions);
  }

  postUserQuickSalaryCheck(id: string, amount: number): Observable<any> {
    let body = { amount };
    return this.http.post(this.authUrl + 'users/' + id + '/quick-salary-check', body, httpOptions)
  }

  /* Profile IMG */
  deleteProfileImage(): Observable<any> {
    return this.http.delete(this.authUrl + 'users/profile/upload', httpOptions);
  }

  downloadProfileImage(id): Observable<any> {
    return this.http.get(this.authUrl + 'users/profile/download/' + id, blobHttpOptions);
  }

  /* User attachment/s download */
  downloadAttachment(id, fileId = null): Observable<any> {
    if (fileId) {
      return this.http.get(this.authUrl + 'users/' + id + '/download-attachments/' + fileId, blobHttpOptions);
    } else {
      return this.http.get(this.authUrl + 'users/talent-profile/download/' + id, blobHttpOptions);
    }
  }

  /* Delete attachment */
  deleteAttachment(id: string, fileId: string, userId = null, type = 'users/'): Observable<any> {
    const url = this.authUrl + type + id + '/delete-attachments/' + (type === 'users/' ? '' : userId + '/') + fileId

    return this.http.delete(url, blobHttpOptions);
  }

  /* Business */

  /* Validate if user can create new business */
  businessValidateUser(ssn: string): Observable<any> {
    return this.http.get(this.authUrl + 'business/validate-user/' + ssn, httpOptions);
  }

  /* Download business documentation */
  businessDownloadDocumentation(fileId = null): Observable<any> {
    return this.http.get(this.authUrl + 'business/download-documentation/' + fileId, blobHttpOptions);
  }

  /* Operators */

  getOperators(search: string, filters: any, pagination?: any, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('operators', search, filters, pagination, sorting);

    return this.http.get<Operator[]>(url, httpOptions);
  }

  getOperator(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'operators/' + id, httpOptions);
  }

  updateOperator(id: string, update: any): Observable<any> {
    return this.http.put(this.authUrl + 'operators/' + id, update, httpOptions);
  }

  postOperator(newOperator: any): Observable<any> {
    return this.http.post(this.authUrl + 'operators', newOperator, httpOptions);
  }

  downloadOperatorAttachment(id, attachment, fileId): Observable<any> {
    return this.http.get(this.authUrl + `operators/download/${id}/${attachment}/${fileId}`, blobHttpOptions);
  }

  /* Chats */
  getUserChats(searchText = undefined): Observable<any> {
    // console.log('apiService.getUserChats() searchText:', searchText);
    let url = 'messages/chats';
    if (searchText && searchText.length > 0) {
      url += '?search=' + encodeURIComponent(searchText);
    }
    console.log('apiService.getUserChats() url:', url);
    return this.http.get(this.authUrl + url, httpOptions);
  }

  getChat(source: string, chatUserId: string): Observable<any> {
    console.log('apiService.getChat() ', source, chatUserId);

    let url = this.authUrl + 'messages/chat' +
      '?source=' + source +
      (chatUserId ? '&chatUserId=' + chatUserId : '');

    return this.http.get(url, httpOptions);
  }

  postMessage(message: any): Observable<any> {
    return this.http.post(this.authUrl + 'messages', message, httpOptions);
  }

  postMessageAction(id, action: string): Observable<any> {
    return this.http.post(this.authUrl + 'messages/' + id, { name: action }, httpOptions);
  }

  postChatAction(source: string, id: string, action: string): Observable<any> {
    let body = { source, chatUserId: id, name: action };
    return this.http.post(this.authUrl + 'messages/chat', body, httpOptions);
  }

  getMessages(search: string, filters: any, pagination?: any): Observable<any> {
    const url = this.buildAuthUrl('messages', search, filters, pagination);
    return this.http.get<Message[]>(url, httpOptions);
  }

  /* Clients */

  getClient(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'clients/' + id, httpOptions);
  }

  searchBusinessByName(search: string): Observable<any> {
    return this.http.get(this.authUrl + 'clients/business/search?search=' + encodeURIComponent(search), httpOptions);
  }

  getBusinessById(companyId: string): Observable<any> {
    return this.http.get(this.authUrl + 'clients/business/' + companyId, httpOptions);
  }

  getPrivateBySsn(ssn: string): Observable<any> {
    return this.http.get(this.authUrl + 'clients/private/' + ssn, httpOptions);
  }

  getNonProfitById(companyId: string): Observable<any> {
    return this.http.get(this.authUrl + 'clients/non-profit/' + companyId, httpOptions);
  }

  // get OVT addresses per business id
  getOvtAddressesByBusinessId(companyId: string): Observable<any> {
    return this.http.get(this.authUrl + 'clients/ovtaddresses/' + companyId, httpOptions);
  }

  getClients(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('clients', search, filters, pagination, sorting);

    return this.http.get<Client[]>(url, httpOptions);
  }

  getUserClients(userId: string): Observable<any> {
    let url = this.authUrl + 'clients/user-clients/' + userId;

    return this.http.get<Client[]>(url, httpOptions);
  }

  postUserClient(userId: string): Observable<any> {
    let url = this.authUrl + 'clients/user-clients/' + userId;

    return this.http.post<{ message: string, client: Client }>(url, { userId }, httpOptions);
  }

  updateClient(id: string, update: any): Observable<any> {
    console.log('ApiService/updateClient() update: ', update);
    if (typeof update.automaticInvoiceApprovalLimit === 'undefined' || update.automaticInvoiceApprovalLimit === 'null') delete update.automaticInvoiceApprovalLimit;
    if (typeof update.automaticPayoutApprovalEnabled === 'undefined' || update.automaticPayoutApprovalEnabled === 'null') delete update.automaticPayoutApprovalEnabled;
    return this.http.put(this.authUrl + 'clients/' + id, update, httpOptions);
  }

  postClient(newClient: any): Observable<any> {
    return this.http.post(this.authUrl + 'clients', newClient, httpOptions);
  }

  deleteClients(clientIds: string[]): Observable<any> {
    return this.http.post(this.authUrl + 'clients/delete', clientIds, httpOptions);
  }

  changeStatusForClients(clientIds: string[], status: string): Observable<any> {
    return this.http.post(this.authUrl + 'clients/set-status/' + status, clientIds, httpOptions);
  }

  creditCheck(id: string): Observable<any> {
    return this.http.post(this.authUrl + 'clients/' + id + '/credit-check', httpOptions);
  }

  getLastCreditCheck(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'clients/' + id + '/credit-check', httpOptions);
  }

  getClientEstimatedPaymentInDays(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'clients/estimated-payment-in-days/' + id, httpOptions);
  }

  /* Wallets */
  getUserWallets(): Observable<any> {
    return this.http.get(this.authUrl + 'wallets', httpOptions);
  }

  updateWallet(walletId, update): Observable<any> {
    return this.http.put(this.authUrl + 'wallets/' + walletId, update, httpOptions);
  }

  getUserTransactions(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('wallets/transactions', search, filters, pagination, sorting);

    return this.http.get(url, httpOptions);
  }

  /* Kyc ID verification */
  getKycUrl(mode = 'identify'): Observable<any> {
    return this.http.get(this.authUrl + 'kyc/start?mode=' + mode, httpOptions);
  }

  postIdVerification(mode: string, token: string): Observable<any> {
    return this.http.post(this.authUrl + 'kyc/verify', { mode, token }, httpOptions).pipe(delay(500));
  }

  /* Business Keywords */
  getBusinessKeywords(search: string): Observable<any> {
    return this.http.get(this.authUrl + 'business-keywords?search=' + encodeURIComponent(search), httpOptions);
  }

  postBusinessKeyword(keyword: string): Observable<any> {
    return this.http.post(this.authUrl + 'business-keywords', { keyword }, httpOptions);
  }

  /* Invoices and Incomes */
  getInvoices(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('invoices', search, filters, pagination, sorting);

    return this.http.get<Invoice[]>(url, httpOptions);
  }

  getIncomes(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('incomes', search, filters, pagination, sorting);

    return this.http.get<Income[]>(url, httpOptions);
  }

  getInvoicesAndIncomes(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    console.log('ApiService/getInvoicesAndIncomes() search: %o; filters: %o; pagination: %o; sorting: %o', search, filters, pagination, sorting);

    // get invoices
    const invoiceUrl = this.buildAuthUrl('invoices', search, filters, pagination, sorting);
    const invoiceRes = this.http.get<Invoice[]>(invoiceUrl, httpOptions);

    // get incomes
    const incomeUrl = this.buildAuthUrl('incomes', search, filters, pagination, sorting);
    const incomeRes = this.http.get<Invoice[]>(incomeUrl, httpOptions);

    // combine results
    return forkJoin({ invoices: invoiceRes, incomes: incomeRes });
  }

  getInvoice(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'invoices/' + id, httpOptions);
  }

  getIncome(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'incomes/' + id, httpOptions);
  }

  getPublicInvoice(id: string): Observable<any> {
    return this.http.get(this.apiUrl + 'invoices/' + id, httpOptions);
  }

  updateInvoice(id: string, update: any): Observable<any> {
    return this.http.put(this.authUrl + 'invoices/' + id, update, httpOptions);
  }

  postInvoice(newInvoice: any): Observable<any> {
    return this.http.post(this.authUrl + 'invoices', newInvoice, httpOptions);
  }

  downloadInvoicesExcel(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('invoices/download-excel', search, filters, pagination, sorting);
    return this.http.get(url, blobHttpOptions);
  }

  updateIncome(id: string, update: any): Observable<any> {
    return this.http.put(this.authUrl + 'incomes/' + id, update, httpOptions);
  }

  postIncome(newInvoice: any): Observable<any> {
    return this.http.post(this.authUrl + 'incomes', newInvoice, httpOptions);
  }

  postSimpleInvoice(newInvoice: any): Observable<any> {
    return this.http.post(this.authUrl + 'invoices/simple-invoice', newInvoice, httpOptions);
  }

  deleteInvoice(id: string): Observable<any> {
    return this.http.delete(this.authUrl + 'invoices/' + id, httpOptions);
  }

  deleteIncome(id: string): Observable<any> {
    return this.http.delete(this.authUrl + 'incomes/' + id, httpOptions);
  }

  getInvoiceURowItems(search: string, userId: string): Observable<any> {
    let url = this.authUrl + 'invoice-u-row-items/' + userId + '?search=' + encodeURIComponent(search);

    return this.http.get<InvoiceURowItem[]>(url, httpOptions);
  }

  postInvoiceURowItem(body: InvoiceURowItem[], userId: string): Observable<any> {
    const url = this.authUrl + 'invoice-u-row-items/' + userId;
    return this.http.post(url, body, httpOptions);
  }

  sendInvoiceForReview(id: string): Observable<any> {
    return this.http.post(this.authUrl + 'invoices/review/send/' + id, httpOptions);
  }

  acceptInvoice(id: string, data: any, isClientApproval: boolean = false): Observable<any> {
    console.log('ApiService/acceptInvoice() data: ', data);
    let endPoint = 'invoices/review/accept/';
    if (typeof data.automaticInvoiceApprovalLimit === 'undefined' || data.automaticInvoiceApprovalLimit === 'null') delete data.automaticInvoiceApprovalLimit;
    if (typeof data.automaticPayoutApprovalEnabled === 'undefined' || data.automaticPayoutApprovalEnabled === 'null') delete data.automaticPayoutApprovalEnabled;
    if (isClientApproval) {
      endPoint = 'invoices/client-review/accept/';
    }
    return this.http.post(this.authUrl + endPoint + id, data, httpOptions);
  }

  acceptClientReviewInvoice(id: string, data: any): Observable<any> {
    console.log('ApiService/acceptInvoice() data: ', data);
    if (typeof data.automaticInvoiceApprovalLimit === 'undefined' || data.automaticInvoiceApprovalLimit === 'null') delete data.automaticInvoiceApprovalLimit;
    if (typeof data.automaticPayoutApprovalEnabled === 'undefined' || data.automaticPayoutApprovalEnabled === 'null') delete data.automaticPayoutApprovalEnabled;
    return this.http.post(this.authUrl + 'invoices/review/accept-client/' + id, data, httpOptions);
  }

  acceptFinanceInvoice(id: string, data: any): Observable<any> {
    console.log('ApiService/acceptFinanceInvoice() data: ', data);
    if (typeof data.automaticInvoiceApprovalLimit === 'undefined' || data.automaticInvoiceApprovalLimit === 'null') delete data.automaticInvoiceApprovalLimit;
    if (typeof data.automaticPayoutApprovalEnabled === 'undefined' || data.automaticPayoutApprovalEnabled === 'null') delete data.automaticPayoutApprovalEnabled;
    return this.http.post(this.authUrl + 'invoices/review/accept-finance/' + id, data, httpOptions);
  }

  rejectInvoice(id: string, message: string, isPublicView: boolean): Observable<any> {
    let endPoint = 'invoices/review/reject/';
    if (isPublicView) endPoint = 'invoices/client-review/reject/';
    return this.http.post(this.authUrl + endPoint + id, { message }, httpOptions);
  }

  cancelInvoice(id: string, message: string): Observable<any> {
    return this.http.post(this.authUrl + 'invoices/cancel/' + id, { message }, httpOptions);
  }

  revertSendInvoiceForReview(id: string): Observable<any> {
    return this.http.post(this.authUrl + 'invoices/revert-send-for-review/' + id, httpOptions);
  }

  refundInvoice(id: string, message: string): Observable<any> {
    return this.http.post(this.authUrl + 'invoices/refund/' + id, { message }, httpOptions);
  }

  markAsPaidInvoice(id: string, message: string): Observable<any> {
    return this.http.post(this.authUrl + 'invoices/markaspaid/' + id, { message }, httpOptions);
  }

  copyInvoiceWithExpense(id: string, itemsToCopy: object): Observable<any> {
    return this.http.post(this.authUrl + 'invoices/copyasnew/' + id, { itemsToCopy }, httpOptions);
  }

  downloadInvoice(id, locale, isPublicView): Observable<any> {
    let endPoint = 'invoices/download/';
    if (isPublicView) endPoint = 'invoices/client-download/';
    return this.http.get(this.authUrl + endPoint + id + '?locale=' + locale, blobHttpOptions);
  }

  downloadInvoiceAttachment(id, isPublicView = false, invoiceAttachment): Observable<any> {
    const url = isPublicView ? this.apiUrl + 'invoices/public-download-attachment/' + id + '/' + invoiceAttachment
    : this.authUrl + 'invoices/download-attachment/' + id + '/' + invoiceAttachment;

    return this.http.get(url, blobHttpOptions);
  }

  extendDueDate(id, update): Observable<any> {
    return this.http.put(this.authUrl + 'invoices/extend-due-date/' + id, update);
  }

  getClientInvoices(freelancerId, clientId): Observable<any> {
    let url = this.authUrl + `invoices/client-invoices/${freelancerId}/${clientId}`;
    return this.http.get<Invoice[]>(url, httpOptions);
  }

  sendNow(id): Observable<any> {
    let url = this.authUrl + `invoices/send-now/${id}`;
    return this.http.post<Invoice[]>(url, httpOptions);
  }


  /* Tax cards */
  postTaxCard(formData): Observable<any> {
    return this.http.post(this.authUrl + 'tax-cards', formData, httpOptions);
  }

  getTaxCard(id): Observable<any> {
    return this.http.get(this.authUrl + 'tax-cards/' + id, httpOptions);
  }

  updateTaxCard(id: string, update: TaxCard): Observable<any> {
    return this.http.put(this.authUrl + 'tax-cards/' + id, update, httpOptions);
  }

  downloadTaxCard(id): Observable<any> {
    return this.http.get(this.authUrl + 'tax-cards/download/' + id, blobHttpOptions);
  }

  postTaxCardAction(id, action): Observable<any> {
    return this.http.post(this.authUrl + 'tax-cards/' + id, { name: action }, httpOptions);
  }

  getTaxCards(search: string, filters: any, pagination?: any, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('tax-cards', search, filters, pagination, sorting);
    return this.http.get<TaxCard[]>(url, httpOptions);
  }

  /* Tax cards - get tax data from VERO API for the current user */
  getTaxDataFromVero(userId?: string): Observable<any> {
    const path = 'tax-cards/vero' + (userId ? '/' + userId : '');
    return this.http.get(this.authUrl + path, httpOptions);
  }

  /* Expenses */
  postExpense(formData): Observable<any> {
    return this.http.post(this.authUrl + 'expenses', formData, httpOptions);
  }

  getExpenses(search: string, filters: any, pagination?: PageEvent, sorting?: Sort, isPublicView = false): Observable<any> {
    const endPoint = isPublicView ? 'expenses-public' : 'expenses';
    const url = this.buildAuthUrl(endPoint, search, filters, pagination, sorting);

    return this.http.get<Expense[]>(url, httpOptions);
  }

  getExpense(id): Observable<Expense> {
    return this.http.get(this.authUrl + 'expenses/' + id, httpOptions);
  }

  updateExpense(id, update): Observable<Expense> {
    return this.http.put(this.authUrl + 'expenses/' + id, update, httpOptions);
  }

  deleteExpense(id): Observable<any> {
    return this.http.delete(this.authUrl + 'expenses/' + id, httpOptions);
  }

  downloadExpense(id, isPublicView = false): Observable<any> {
    const url = isPublicView ? this.apiUrl + 'expenses/public-download/' + id
    : this.authUrl + 'expenses/download/' + id;

    return this.http.get(url, blobHttpOptions);
  }

  downloadExpenseAttachment(id: string, isPublicView = false): Observable<any> {
    const url = isPublicView ? this.apiUrl + 'expenses/public-download-attachment/' + id
      : this.authUrl + 'expenses/download-attachment/' + id;

    return this.http.get(url, blobHttpOptions);
  }

  downloadExpenseReceipt(id, locale): Observable<any> {
    return this.http.get(this.authUrl + 'expenses/download-receipt/' + id + '?locale=' + locale, blobHttpOptions);
  }

  /* Expenditures */
  postExpenditure(formData): Observable<any> {
    return this.http.post(this.authUrl + 'expenditures', formData, httpOptions);
  }

  getExpenditures(search: string, filters: any, pagination?: PageEvent, sorting?: Sort, isPublicView = false): Observable<any> {
    const endPoint = isPublicView ? 'expenditure-public' : 'expenditures';
    const url = this.buildAuthUrl(endPoint, search, filters, pagination, sorting);
    return this.http.get<Expense[]>(url, httpOptions);
  }

  getExpenditure(id): Observable<Expense> {
    return this.http.get(this.authUrl + 'expenditures/' + id, httpOptions);
  }

  updateExpenditure(id, update): Observable<Expense> {
    return this.http.put(this.authUrl + 'expenditures/' + id, update, httpOptions);
  }

  deleteExpenditure(id): Observable<any> {
    return this.http.delete(this.authUrl + 'expenditures/' + id, httpOptions);
  }

  postExpenditureAction(id: string, action: string): Observable<any> {
    return this.http.post(this.authUrl + 'expenditures/' + id, { name: action }, httpOptions);
  }

  downloadExpenditure(id: string, isPublicView = false): Observable<any> {
    const url = isPublicView ? this.apiUrl + 'expenditures/public-download/' + id
      : this.authUrl + 'expenditures/download/' + id;

    return this.http.get(url, blobHttpOptions);
  }

  downloadExpenditureAttachment(id, isPublicView = false): Observable<any> {
    const url = isPublicView ? this.apiUrl + 'expenditures/public-download-attachment/' + id
      : this.authUrl + 'expenditures/download-attachment/' + id;

    return this.http.get(url, blobHttpOptions);
  }

  downloadExpenditureReceipt(id, locale): Observable<any> {
    return this.http.get(this.authUrl + 'expenditures/download-receipt/' + id + '?locale=' + locale, blobHttpOptions);
  }

  /* Payouts */
  getPayouts(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('payouts', search, filters, pagination, sorting);

    return this.http.get(url, httpOptions);
  }

  postPayout(formData): Observable<any> {
    return this.http.post(this.authUrl + 'payouts', formData, httpOptions);
  }

  postPayoutsBulkAction(ids: string[], action: string): Observable<any> {
    return this.http.post(this.authUrl + 'payouts/bulk-action', { ids, action }, httpOptions);
  }

  postPayoutAction(id, action): Observable<any> {
    return this.http.post(this.authUrl + 'payouts/' + id, { name: action }, httpOptions);
  }

  getPayout(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'payouts/' + id, httpOptions);
  }

  updatePayout(id: string, update: any): Observable<any> {
    return this.http.put(this.authUrl + 'payouts/' + id, update, httpOptions);
  }

  updatePayoutTaxCard(id: string, data: any): Observable<any> {
    // console.log('updatePayoutTaxCard() make HTTP PUT call: ' + this.authUrl + 'payouts/updatetaxcard/' + id);
    return this.http.put(this.authUrl + 'payouts/updatetaxcard/' + id, data, httpOptions);
  }

  downloadPayslip(id, locale): Observable<any> {
    return this.http.get(this.authUrl + 'payouts/download/' + id + '?locale=' + locale, blobHttpOptions);
  }

  postSalary(data): Observable<any> {
    return this.http.post(this.authUrl + 'payouts/salary', data, httpOptions);
  }

  calculateSalary(amount: number, taxRate: number, yelInsured: boolean): Observable<any> {
    let url = this.authUrl + 'payouts/calculate-salary/' + amount + '/' + taxRate + '/' + yelInsured;
    return this.http.get(url, httpOptions);
  }

  getUserPayouts(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'payouts/user-payouts/' + id, httpOptions);
  }

  getLastDebtcollectionPayouts(id: string, payoutId: string): Observable<any> {
    return this.http.get(this.authUrl + 'payouts/last-debt-collection/' + id + '/' + payoutId, httpOptions);
  }

  downloadPayoutsExcel(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('payouts/download-excel', search, filters, pagination, sorting);
    return this.http.get(url, blobHttpOptions);
  }

  /* Settlements */
  getSettlements(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('settlements', search, filters, pagination, sorting);
    return this.http.get(url, httpOptions);
  }

  postSettlement(formData): Observable<any> {
    return this.http.post(this.authUrl + 'settlements', formData, httpOptions);
  }

  postSettlementAction(id, action): Observable<any> {
    return this.http.post(this.authUrl + 'settlements/' + id, { name: action }, httpOptions);
  }

  getSettlement(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'settlements/' + id, httpOptions);
  }

  updateSettlement(id: string, update: any): Observable<any> {
    return this.http.put(this.authUrl + 'settlements/' + id, update, httpOptions);
  }

  // NEEDED?
  updateSettlementTaxCard(id: string, data: any): Observable<any> {
    console.log('ApiService/updateSettlementTaxCard() OBSOLETE?');
    // console.log('updatePayoutTaxCard() make HTTP PUT call: ' + this.authUrl + 'payouts/updatetaxcard/' + id);
    return this.http.put(this.authUrl + 'settlements/updatetaxcard/' + id, data, httpOptions);
  }

  downloadSettlementReceipt(id, locale, version = 1): Observable<any> {
    return this.http.get(this.authUrl + 'settlements/download/' + id + '?locale=' + locale + '&version=' + version, blobHttpOptions);
  }

  downloadSettlementsExcel(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('settlements/download-excel', search, filters, pagination, sorting);
    return this.http.get(url, blobHttpOptions);
  }

  postSettlementCalc(data): Observable<any> {
    console.log('ApiService/postSettlementCalc()');
    return this.http.post(this.authUrl + 'settlements/calc', data, httpOptions);
  }

  calculateSettlement(amountNet: number, amountGross: number): Observable<any> {
    console.log('ApiService/calculateSettlement()');
    let url = this.authUrl + 'settlements/calculate-settlement/' + amountNet + '/' + amountGross;
    return this.http.get(url, httpOptions);
  }

  getUserSettlements(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'settlements/user-settlements/' + id, httpOptions);
  }

  // NEEDED?
  getLastDebtcollectionSettlements(id, settlementId): Observable<any> {
    console.log('ApiService/getLastDebtcollectionSettlements() OBSOLETE?');
    return this.http.get(this.authUrl + 'settlements/last-debt-collection/' + id + '/' + settlementId, httpOptions);
  }

  /* Business Statements */

  getBusinessStatements(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('business-statements', search, filters, pagination, sorting);
    return this.http.get(url, httpOptions);
  }

  getBusinessStatement(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'business-statements/' + id, httpOptions);
  }

  postBusinessStatementAction(id: string, action: string, message: string = null): Observable<any> {
    const body = { name: action, ...(message && { message })};
    return this.http.post(this.authUrl + 'business-statements/' + id, body, httpOptions);
  }

  getBusinessStatementCalculation(userId: string): Observable<any> {
    return this.http.get(this.authUrl + 'business-statements/calculate/' + userId, httpOptions);
  }

  /* YEL */
  getYelStatus(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'yel/status/' + id, httpOptions);
  }

  /* Jobs */
  postJob(formData): Observable<any> {
    return this.http.post(this.authUrl + 'jobs', formData, httpOptions);
  }
  getJob(id): Observable<WorkNotice> {
    return this.http.get(this.authUrl + 'jobs/' + id, httpOptions);
  }

  getJobs(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('jobs', search, filters, pagination, sorting);

    return this.http.get<Job[]>(url, httpOptions);
  }
  updateJob(id, update): Observable<WorkNotice> {
    return this.http.put(this.authUrl + 'jobs/' + id, update, httpOptions);
  }
  deleteJob(id): Observable<any> {
    return this.http.delete(this.authUrl + 'jobs/' + id, httpOptions);
  }

  /* Work Shifts */
  postWorkShift(formData): Observable<any> {
    return this.http.post(this.authUrl + 'work-shifts', formData, httpOptions);
  }
  getWorkShift(id): Observable<WorkShift> {
    return this.http.get(this.authUrl + 'work-shifts/' + id, httpOptions);
  }
  getWorkShifts(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('work-shifts', search, filters, pagination, sorting);

    return this.http.get<Job[]>(url, httpOptions);
  }
  updateWorkShift(id, update): Observable<WorkShift> {
    return this.http.put(this.authUrl + 'work-shifts/' + id, update, httpOptions);
  }
  deleteWorkShift(id): Observable<any> {
    return this.http.delete(this.authUrl + 'work-shifts/' + id, httpOptions);
  }

  /* Taxi rides */
  postTaxiRide(formData): Observable<any> {
    return this.http.post(this.authUrl + 'taxi-rides', formData, httpOptions);
  }
  getTaxiRide(id: string): Observable<TaxiRide> {
    return this.http.get<TaxiRide>(this.authUrl + 'taxi-rides/' + id, httpOptions);
  }
  getTaxiRides(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('taxi-rides', search, filters, pagination, sorting);
    return this.http.get(url, httpOptions);
  }
  updateTaxiRide(id, update): Observable<TaxiRide> {
    return this.http.put<TaxiRide>(this.authUrl + 'taxi-rides/' + id, update, httpOptions);
  }
  deleteTaxiRide(id): Observable<any> {
    return this.http.delete(this.authUrl + 'taxi-rides/' + id, httpOptions);
  }

  /* Taxi Contracts */
  postTaxiContract(formData): Observable<any> {
    return this.http.post(this.authUrl + 'taxi-contracts', formData, httpOptions);
  }
  getTaxiContract(id: string): Observable<TaxiContract> {
    return this.http.get<TaxiContract>(this.authUrl + 'taxi-contracts/' + id, httpOptions);
  }
  getTaxiContracts(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('taxi-contracts', search, filters, pagination, sorting);
    return this.http.get(url, httpOptions);
  }
  updateTaxiContract(id, update): Observable<TaxiContract> {
    return this.http.put<TaxiContract>(this.authUrl + 'taxi-contracts/' + id, update, httpOptions);
  }
  deleteTaxiContract(id): Observable<any> {
    return this.http.delete(this.authUrl + 'taxi-contracts/' + id, httpOptions);
  }
  doTaxiContractAction(id, action): Observable<any> {
    return this.http.put(this.authUrl + 'taxi-contracts/' + id + '/' + action, httpOptions);
  }
  getTaxiCompanies(): Observable<any> {
    return this.http.get(this.authUrl + 'taxi-contracts/taxi-companies', httpOptions);
  }
  getTaxiDriverUsers(taxiCompanyId): Observable<any> {
    return this.http.get(this.authUrl + 'taxi-contracts/taxi-drivers/' + taxiCompanyId, httpOptions);
  }

  /* Taxi Contract Items */
  postTaxiContractItem(formData): Observable<any> {
    return this.http.post(this.authUrl + 'taxi-contract-items', formData, httpOptions);
  }
  getTaxiContractItem(id): Observable<TaxiContractItem> {
    return this.http.get(this.authUrl + 'taxi-contract-items/' + id, httpOptions);
  }
  getTaxiContractItems(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('taxi-contract-items', search, filters, pagination, sorting);
    return this.http.get(url, httpOptions);
  }
  updateTaxiContractItem(id, update): Observable<TaxiContractItem> {
    return this.http.put(this.authUrl + 'taxi-contract-items/' + id, update, httpOptions);
  }
  deleteTaxiContractItem(id): Observable<any> {
    return this.http.delete(this.authUrl + 'taxi-contract-items/' + id, httpOptions);
  }
  doTaxiContractItemAction(id, action): Observable<any> {
    return this.http.put(this.authUrl + 'taxi-contract-items/' + id + '/' + action, httpOptions);
  }

  /* BankTransferTransactions */
  postBankTransferTransaction(formData): Observable<any> {
    return this.http.post(this.authUrl + 'bank-transfer-transactions', formData, httpOptions);
  }
  getBankTransferTransaction(id): Observable<WorkNotice> {
    return this.http.get(this.authUrl + 'bank-transfer-transactions/' + id, httpOptions);
  }
  getBankTransferTransactions(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('bank-transfer-transactions', search, filters, pagination, sorting);
    return this.http.get<BankTransferTransaction[]>(url, httpOptions);
  }
  updateBankTransferTransaction(id, update): Observable<WorkNotice> {
    return this.http.put(this.authUrl + 'bank-transfer-transactions/' + id, update, httpOptions);
  }
  deleteBankTransferTransaction(id): Observable<any> {
    return this.http.delete(this.authUrl + 'bank-transfer-transactions/' + id, httpOptions);
  }
  reprocessBankTransferTransaction(id, formData): Observable<any> {
    return this.http.post(this.authUrl + 'bank-transfer-transactions/reprocess/' + id, formData, httpOptions);
  }
  rejectBankTransferTransaction(id, formData): Observable<any> {
    return this.http.post(this.authUrl + 'bank-transfer-transactions/reject/' + id, formData, httpOptions);
  }

  /* Work notices */
  postWorkNotice(formData): Observable<any> {
    return this.http.post(this.authUrl + 'work-notices', formData, httpOptions);
  }

  getWorkNotice(id): Observable<WorkNotice> {
    return this.http.get(this.authUrl + 'work-notices/' + id, httpOptions);
  }

  getWorkNotices(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('work-notices', search, filters, pagination, sorting);
    return this.http.get<WorkNotice[]>(url, httpOptions);
  }

  updateWorkNotice(id, update): Observable<WorkNotice> {
    return this.http.put(this.authUrl + 'work-notices/' + id, update, httpOptions);
  }

  deleteWorkNotice(id): Observable<any> {
    return this.http.delete(this.authUrl + 'work-notices/' + id, httpOptions);
  }

  /* Insurances */
  getInsurances(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('insurances', search, filters, pagination, sorting);

    return this.http.get(url, httpOptions);
  }

  postInsuranceAction(id, action): Observable<any> {
    return this.http.post(this.authUrl + 'insurances/' + id, { name: action }, httpOptions);
  }

  /* JSON data */
  getSectors(): Observable<any> {
    return this.http.get(this.authUrl + 'data/business-sectors', httpOptions);
  }
  getSectorsSimple(): Observable<any> {
    return this.http.get(this.authUrl + 'data/business-sectors-simple', httpOptions);
  }
  getProfessions(): Observable<any> {
    return this.http.get(this.authUrl + 'data/professions', httpOptions);
  }

  /* Hobbies */
  getHobbies(search: string): Observable<any> {
    console.log('getHobbies()');
    return this.http.get(this.authUrl + 'hobbies?search=' + encodeURIComponent(search), httpOptions);
  }

  postHobby(title: string): Observable<any> {
    console.log('postHobby()');
    return this.http.post(this.authUrl + 'hobbies', { title }, httpOptions);
  }

  /* Languages */
  getLanguages(search: string): Observable<any> {
    console.log('getLanguages()');
    return this.http.get(this.authUrl + 'languages?search=' + encodeURIComponent(search), httpOptions);
  }

  postLanguage(title: string): Observable<any> {
    console.log('postLanguage()');
    return this.http.post(this.authUrl + 'languages', { title }, httpOptions);
  }

  /* Communities */
  getCommunities(search: string): Observable<any> {
    console.log('getCommunities()');
    return this.http.get(this.authUrl + 'communities?search=' + encodeURIComponent(search), httpOptions);
  }

  /* Competences */
  getCompetences(search: string): Observable<any> {
    console.log('getCompetences()');
    return this.http.get(this.authUrl + 'competences?search=' + encodeURIComponent(search), httpOptions);
  }

  postCompetence(title: string): Observable<any> {
    console.log('postCompetence()');
    return this.http.post(this.authUrl + 'competences', { title }, httpOptions);
  }

  /* Skills */
  getSkills(competenceIds: any, search: string): Observable<any> {
    console.log('getSkills() competenceIds:', competenceIds);
    return this.http.get(this.authUrl + 'competences/' + competenceIds + '/skills?search=' + encodeURIComponent(search), httpOptions);
  }

  postSkill(competenceId: string, title: string): Observable<any> {
    console.log('postSkill()');
    return this.http.post(this.authUrl + 'competences/' + competenceId + '/skills', { title }, httpOptions);
  }

  // Dashboard
  getDashboardSummary(client?: string): Observable<any> {
    return this.http.get(this.authUrl + 'dashboard/summary' + (client ? '?client=' + client : ''));
  }

  // TODO: Move to own services for charts.
  getClientsByBusinessSector(): Observable<any> {
    return this.http.get(this.authUrl + 'dashboard/clients-by-business-sector');
  }

  getAdminBusinessTrends(): Observable<any> {
    return this.http.get(this.authUrl + 'dashboard/admin-business-trends');
  }

  getNonAdminBusinessTrends(): Observable<any> {
    return this.http.get(this.authUrl + 'dashboard/non-admin-business-trends');
  }

  getInvoicingPerMonth(): Observable<any> {
    return this.http.get(this.authUrl + 'dashboard/invoicing-per-month');
  }

  // YEL
  getEstimatedInvoicing(lastMonthsFrom = 2, lastMonthsTo = 3, outputCycle = 12): Observable<any> {
    let url = this.authUrl + 'yel/estimate-invoicing' + '?lastMonthsFrom=' + lastMonthsFrom +
      '&lastMonthsTo=' + lastMonthsTo + '&outputCycle=' + outputCycle;

    return this.http.get(url);
  }

  /* REPORTS */
  getIncomeReport(filters: any): Observable<any> {
    const url = this.buildAuthUrl('reports/income', null, filters);

    return this.http.get(url, httpOptions);
  }

  downloadIncomeReport(filters: any): Observable<any> {
    const url = this.buildAuthUrl('reports/income/download', null, filters);

    return this.http.get(url, blobHttpOptions);
  }

  getCumulativeReport(filters: any): Observable<any> {
    const url = this.buildAuthUrl('reports/cumulative', null, filters);

    return this.http.get(url, httpOptions);
  }

  downloadCumulativeReport(filters: any): Observable<any> {
    const url = this.buildAuthUrl('reports/cumulative/download', null, filters);

    return this.http.get(url, blobHttpOptions);
  }

  getSalaryReport(filters: any): Observable<any> {
    const url = this.buildAuthUrl('reports/salary', null, filters);

    return this.http.get(url, httpOptions);
  }
  downloadSalaryReport(filters: any): Observable<any> {
    const url = this.buildAuthUrl('reports/salary/download', null, filters);

    return this.http.get(url, blobHttpOptions);
  }

  getMonthlyTaxesReport(filters: any): Observable<any> {
    let url = this.authUrl + 'reports/monthly-taxes' + '?year=' + filters.year;
    if (filters.operator) {
      url += '&operator=' + filters.operator;
    }
    return this.http.get(url, httpOptions);
  }

  getOperatorFeesReport(filters: any): Observable<any> {
    let url = this.authUrl + 'reports/operator-fees' + '?year=' + filters.year;
    if (filters.operator) {
      url += '&operator=' + filters.operator;
    }
    return this.http.get(url, httpOptions);
  }

  getAccountingReport(filters: any): Observable<any> {
    let url = this.authUrl + 'reports/accounting?date=' + filters.date;
    if (filters.operator) {
      url += '&operator=' + filters.operator;
    }
    return this.http.get(url, httpOptions);
  }

  getAccountingReportV2(filters: any): Observable<any> {
    let url = this.authUrl + 'reports/accounting-v2?date=' + filters.date;
    if (filters.operator) {
      url += '&operator=' + filters.operator;
    }
    return this.http.get(url, httpOptions);
  }

  downloadAccountingReport(filters: any): Observable<any> {
    let url = this.authUrl + 'reports/accounting/download' + '?locale=' + filters.locale + '&date=' + filters.date;
    if (filters.operator) {
      url += '&operator=' + filters.operator
    }

    return this.http.get(url, blobHttpOptions);
  }

  downloadAccountingReportV2(filters: any): Observable<any> {
    let url = this.authUrl + 'reports/accounting-v2/download' + '?locale=' + filters.locale + '&date=' + filters.date;
    return this.http.get(url, blobHttpOptions);
  }

  getWorkShiftsReport(filters: any): Observable<any> {
    const url = this.buildAuthUrl('reports/work-shifts', null, filters);
    return this.http.get(url, httpOptions);
  }
  downloadWorkShiftsReport(filters: any, reportType = 'pdf'): Observable<any> {
    // download pdf or excel report
    const url = this.buildAuthUrl('reports/work-shifts/download/' + reportType, null, filters);
    return this.http.get(url, blobHttpOptions);
  }

  getTaxiRidesReport(filters: any): Observable<any> {
    const url = this.buildAuthUrl('reports/taxi-rides', null, filters);
    return this.http.get(url, httpOptions);
  }
  downloadTaxiRidesReport(filters: any, reportType = 'pdf'): Observable<any> {
    const url = this.buildAuthUrl('reports/taxi-rides/download/' + reportType, null, filters);
    return this.http.get(url, blobHttpOptions);
  }

  getAccountingEventsReport(filters: any): Observable<any> {
    const url = this.buildAuthUrl('reports/accounting-events', null, filters);
    return this.http.get(url, httpOptions);
  }
  downloadAccountingEventsReport(filters: any, reportType = 'pdf'): Observable<any> {
    const url = this.buildAuthUrl('reports/accounting-events/download/' + reportType, null, filters);
    return this.http.get(url, blobHttpOptions);
  }

  //
  // notifications
  //
  createNotification(data: any): Observable<any> {
    console.log('createNotification()');
    return this.http.post(this.authUrl + 'notifications', data, httpOptions);
  }

  getNotifications(userPublicId): Observable<any> {
    console.log('getNotifications()');
    return this.http.get(this.authUrl + 'notifications/' + userPublicId, httpOptions);
  }

  // Not used for now, but left in case it is needed
  updateNotification(id, userPublicId, update): Observable<any> {
    console.log('updateNotification()');
    return this.http.put(this.authUrl + 'notifications/' + id + '/' + userPublicId, update, httpOptions);
  }

  notificationAction(id, userPublicId, action): Observable<any> {
    console.log('notificationAction()');
    return this.http.put(this.authUrl + `notifications/${action}/${id}/${userPublicId}`, httpOptions);
  }

  downloadMessageFile(authId, fileId): Observable<any> {
    return this.http.get(this.authUrl + 'messages/download/' + fileId + '?authId=' + encodeURIComponent(authId), blobHttpOptions);
  }

  reSendEmailForClientReview(invoiceId): Observable<any> {
    return this.http.post(this.authUrl + 'invoices/resend-email-client/' + invoiceId, httpOptions);
  }

  /* Marketing */
  postBonus(data): Observable<any> {
    return this.http.post(this.authUrl + 'marketing/bonus', data, httpOptions);
  }

  getReferrals(search: string, filters: any, pagination?: any, sorting?: any): Observable<any> {
    const url = this.buildAuthUrl('marketing/referrals', search, filters, pagination, sorting);
    return this.http.get<User[]>(url, httpOptions);
  }
  getReferralsAutocomplete(search: string, filters: any, pagination?: any, sorting?: any): Observable<any> {
    const url = this.buildAuthUrl('marketing/referrals/autocomplete', search, filters, pagination, sorting);
    return this.http.get<User[]>(url, httpOptions);
  }

  createCampaign(formData): Observable<any> {
    return this.http.post(this.authUrl + 'marketing/campaigns/', formData, httpOptions);
  }
  getCampaigns(search: string, filters: any, pagination?: any, sorting?: any): Observable<any> {
    const url = this.buildAuthUrl('marketing/campaigns', search, filters, pagination, sorting);
    return this.http.get<User[]>(url, httpOptions);
  }
  updateCampaign(id: string, update: any): Observable<Campaign> {
    return this.http.put(this.authUrl + 'marketing/campaign/' + id, update, httpOptions);
  }

  deleteCampaign(id): Observable<any> {
    return this.http.delete(this.authUrl + 'marketing/campaign/' + id, httpOptions);
  }

  getMapUsers(search: string, filters: any, pagination?: any, sorting?: any): Observable<any> {
    const url = this.buildAuthUrl('map/users', search, filters, pagination, sorting);

    return this.http.get<User[]>(url, httpOptions);
  }

  getMapClients(search: string, filters: any, pagination?: any, sorting?: any): Observable<any> {
    const url = this.buildAuthUrl('map/clients', search, filters, pagination, sorting);
    return this.http.get<Client[]>(url, httpOptions);
  }

  /* Talent Search */
  getTalents(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('talents', search, filters, pagination, sorting);

    return this.http.get<User[]>(url, httpOptions);
  }

  getTalent(id: string): Observable<any> {
    return this.http.get(this.authUrl + 'talents/' + id, httpOptions);
  }

  downloadTalentCv(id, locale): Observable<any> {
    return this.http.get(this.authUrl + 'talents/download/' + id + '?locale=' + locale, blobHttpOptions);
  }

  /* Work hours */
  postWorkHours(formData): Observable<any> {
    return this.http.post(this.authUrl + 'work-hours', formData, httpOptions);
  }

  getWorkHoursList(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('work-hours-list', search, filters, pagination, sorting);

    return this.http.get<WorkHours[]>(url, httpOptions);
  }

  updateWorkHours(id, update) {
    return this.http.put(this.authUrl + 'work-hours/' + id, update, httpOptions);
  }

  deleteWorkHours(id) {
    return this.http.delete(this.authUrl + 'work-hours/' + id, httpOptions);
  }

  getLatestWorkHours(userId, limit = 1, clientId = null) {
    return this.http.get(this.authUrl + `work-hours/latest-work-hours/${userId}/${limit}/${clientId}`, httpOptions);
  }

  setWorkHoursAsInvoiced(update) {
    return this.http.put(this.authUrl + 'work-hours-list/', update, httpOptions);
  }

  removeWorkHoursFromInvoice(update) {
    return this.http.put(this.authUrl + 'work-hours-list/', update, httpOptions);
  }

  /* Business Profile */
  getBICByIBAN(bankAccountIban) {
    return this.http.get(this.authUrl + `businessprofile/check-bic/${bankAccountIban}`, httpOptions);
  }

  /* Guardianships */
  getGuardianships() {
    return this.http.get(this.authUrl + 'taxis/guardianships/', httpOptions);
  }

  /* AI features */
  getAiInvoiceCreation(message) {
    console.log('ApiService/getAiInvoiceCreation()');
    return this.http.get(this.authUrl + 'ai/invoice/' + message, httpOptions);
  }

  postSpeechToText(audioChunks, dataUri, selectedMimeType, locale): Observable<any> {
    console.log('ApiService/postSpeechToText()');
    return this.http.post(this.authUrl + 'ai/speechtotext', { audioData: audioChunks, dataUri, selectedMimeType, locale }, httpOptions);
  }

  postAiTranslate(text, locale): Observable<any> {
    console.log('ApiService/postAiTranslate()');
    return this.http.post(this.authUrl + 'ai/translate', { text, locale }, httpOptions);
  }

  /* Import data */
  postImportData(formData): Observable<any> {
    return this.http.post(this.authUrl + 'import-datas', formData, importDataHttpOptions);
  }

  postImportDataWithStatus(formData): Observable<any> {
    // Start the import process
    if (!formData.doAsync) {
      formData.doAsync = true;
    }
    return this.http.post<any>(this.authUrl + 'import-datas', formData, importDataHttpOptions).pipe(
      switchMap((response: any) => {
        const importId = response._id;
        console.log('ApiService/postImportDataWithStatus() import started; importId:', importId);
        // Poll for status every 5 seconds
        return interval(5000).pipe(
          switchMap(() => this.getImportData(importId).pipe(
            map((importData: any) => ({
              status: importData.status,
              _id: importData._id,
              uuid: importData.uuid,
              result: importData.result,
              importData: importData,
              error: importData.error
            }))
          )),
          takeWhile((impData: any) => impData.status !== 'completed' && impData.status !== 'failed', true)
        );
      })
    );
  }

  getImportData(id): Observable<ImportData> {
    return this.http.get(this.authUrl + 'import-datas/' + id, httpOptions);
  }

  getImportDataList(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('import-datas', search, filters, pagination, sorting);

    return this.http.get<any[]>(url, importDataHttpOptions);
  }

  updateImportData(id, update) {
    return this.http.put(this.authUrl + 'import-datas/' + id, update, importDataHttpOptions);
  }

  deleteImportData(id) {
    return this.http.delete(this.authUrl + 'import-datas/' + id, importDataHttpOptions);
  }

  // Subscriptions
  getSubscription(id: string): Observable<Subscription> {
    return this.http.get<Subscription>(this.authUrl + 'subscriptions/' + id, httpOptions);
  }

  getSubscriptions(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<{ total: number, subscriptions: Subscription[] }> {
    return this.http.get<{ total: number, subscriptions: Subscription[] }>(
      this.buildAuthUrl('subscriptions', search, filters, pagination, sorting),
      httpOptions
    );
  }

  postSubscriptionAction(id: string, action: string): Observable<Subscription> {
    return this.http.post<Subscription>(this.authUrl + 'subscriptions/' + id, { name: action }, httpOptions);
  }

  postSubscription(subscription: Subscription, activate = false): Observable<Subscription> {
    return this.http.post<Subscription>(this.authUrl + 'subscriptions', { ...subscription, activate }, httpOptions);
  }

  updateSubscription(id: string, subscription: Subscription): Observable<Subscription> {
    return this.http.put<Subscription>(this.authUrl + 'subscriptions/' + id, subscription, httpOptions);
  }

  // UserFees
  getUserFees(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<{ total: number, userFees: UserFee[] }> {
    return this.http.get<{ total: number, userFees: UserFee[] }>(
      this.buildAuthUrl('user-fees', search, filters, pagination, sorting),
      httpOptions
    );
  }

  postUserFee(userFee: UserFee, activate = false): Observable<UserFee> {
    return this.http.post<UserFee>(this.authUrl + 'user-fees', { ...userFee, activate }, httpOptions);
  }

  updateUserFee(id: string, userFee: UserFee): Observable<UserFee> {
    return this.http.put<UserFee>(this.authUrl + 'user-fees/' + id, userFee, httpOptions);
  }

  removeUserFee(id: string): Observable<UserFee> {
    return this.http.delete<UserFee>(this.authUrl + 'user-fees/' + id, httpOptions);
  }

  // User Subscriptions
  getUserSubscriptions(userId: string): Observable<UserSubscription[]> {
    return this.http.get<UserSubscription[]>(
      this.authUrl + 'user-subscriptions?userId=' + userId, httpOptions
    );
  }

  postUserSubscription(userId: string, params: { billingCycle: string, startDate: Date }, subscriptionId: string): Observable<UserSubscription> {
    return this.http.post<UserSubscription>(
      this.authUrl + 'user-subscriptions', { userId, ...params, subscriptionId },
      httpOptions
    );
  }

  postUserSubscriptionAction(id: string, action: string): Observable<UserSubscription> {
    return this.http.post<UserSubscription>(this.authUrl + 'user-subscriptions/' + id, { name: action }, httpOptions);
  }

  // Liability Data
  getLiabilityDatas(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<{ total: number, liabilityDatas: LiabilityData[] }> {
    return this.http.get<{ total: number, liabilityDatas: LiabilityData[] }>(
      this.buildAuthUrl('liability-data', search, filters, pagination, sorting),
      httpOptions
    );
  }

  updateLiabilityData(id: string, update: LiabilityData): Observable<LiabilityData> {
    return this.http.put<LiabilityData>(this.authUrl + 'liability-data/' + id, update, httpOptions);
  }

  getLiabilityDataForUser(): Observable<LiabilityData> {
    return this.http.get<LiabilityData>(this.authUrl + 'liability-data/user', httpOptions);
  }

  getLiabilityData(id: string): Observable<LiabilityData> {
    return this.http.get<LiabilityData>(this.authUrl + 'liability-data/' + id, httpOptions);
  }

  getPublicLiabilityData(uuid: string): Observable<LiabilityData> {
    return this.http.get<LiabilityData>(this.apiUrl + 'liability-data/' + uuid, httpOptions);
  }

  downloadLiabilityDataDocument(id: string, type: string, fileId: string): Observable<any> {
    return this.http.get(this.authUrl + 'liability-data/' + id + '/download/' + type + '/' + fileId, blobHttpOptions);
  }

  /*
    Work Shifts
  */
  putWorkShiftAction(workShiftId, action): Observable<WorkShift> {
    return this.http.put<WorkShift>(this.authUrl + 'work-shifts/' + workShiftId + '/' + action, httpOptions);
  }

  /*
    Accounting Events
  */
  getAccountingEvents(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    return this.http.get<any[]>(this.buildAuthUrl('accounting-events', search, filters, pagination, sorting), httpOptions);
  }
  getAccountingEvent(id: string): Observable<AccountingEvent> {
    return this.http.get<AccountingEvent>(this.authUrl + 'accounting-events/' + id, httpOptions);
  }
  postAccountingEvent(accountingEvent: AccountingEvent): Observable<AccountingEvent> {
    return this.http.post<AccountingEvent>(this.authUrl + 'accounting-events', accountingEvent, httpOptions);
  }
  putAccountingEvent(id: string, accountingEvent: AccountingEvent): Observable<AccountingEvent> {
    return this.http.put<AccountingEvent>(this.authUrl + 'accounting-events/' + id, accountingEvent, httpOptions);
  }
  deleteAccountingEvent(id: string): Observable<AccountingEvent> {
    return this.http.delete<AccountingEvent>(this.authUrl + 'accounting-events/' + id, httpOptions);
  }

  /* Multi Expenses */
  getPublicMultiExpense(externalId: string, userId: string, source: string): Observable<MultiExpenseResponseDTO> {
    const url = this.apiUrl + 'multi-expenses/' + externalId + '?userId=' + userId + (source ? '&source=' + source : '');
    return this.http.get<MultiExpenseResponseDTO>(url, httpOptions);
  }

  putPublicMultiExpense(externalId: string, body: MultiExpense) {
    return this.http.put<MultiExpense>(this.apiUrl + 'multi-expenses/' + externalId, body, httpOptions);
  }

  downloadMultiExpenseReceipt(id: string, fileId: string): Observable<any> {
    return this.http.get(this.apiUrl + 'multi-expenses/' + id + '/download-receipt/' + fileId, blobHttpOptions);
  }

  getPublicReceiptAnalysis(id: string, fileId: string): Observable<AIReceiptCreationResponse> {
    return this.http.get<AIReceiptCreationResponse>(this.apiUrl + 'multi-expenses/' + id + '/analyze-receipt/' + fileId, httpOptions);
  }

  /* Operator Fees */
  getOperatorFees(search: string, filters: any, pagination?: PageEvent, sorting?: Sort): Observable<any> {
    const url = this.buildAuthUrl('operator-fees', search, filters, pagination, sorting);
    return this.http.get<any[]>(url, httpOptions);
  }
  getOperatorFee(id: string): Observable<OperatorFee> {
    return this.http.get<OperatorFee>(this.authUrl + 'operator-fees/' + id, httpOptions);
  }
  postOperatorFee(operatorFee: OperatorFee): Observable<OperatorFee> {
    return this.http.post<OperatorFee>(this.authUrl + 'operator-fees', operatorFee, httpOptions);
  }
  putOperatorFee(id: string, operatorFee: OperatorFee): Observable<OperatorFee> {
    return this.http.put<OperatorFee>(this.authUrl + 'operator-fees/' + id, operatorFee, httpOptions);
  }
  deleteOperatorFee(id: string): Observable<OperatorFee> {
    return this.http.delete<OperatorFee>(this.authUrl + 'operator-fees/' + id, httpOptions);
  }


  // eof
}
