import { FuseNavigation } from '@fuse/types';
import { AppSettingsConfig } from '@app/core/configs/app-settings.config';

const theme = (new AppSettingsConfig).theme;

export const navigation: FuseNavigation[] = [
  {
    id: 'user_menu',
    translate: 'menu.menu',
    type: 'group',
    title: 'Menu',
    icon: 'apps',
    children: [
      {
        id: 'dashboards',
        title: 'Dashboard',
        translate: 'menu.dashboard',
        icon: 'dashboard',
        type: 'item',
        url: '/dashboard'
      },
      {
        id: 'messaging',
        title: 'Messaging',
        translate: 'menu.messaging',
        icon: 'chat',
        type: 'item',
        url: '/chat',
        badge: {
          title: '',
          bg: theme.accent, // bg: '#09d261',
          fg: theme.bg1
        }
      },
      {
        id: 'clients',
        title: 'Clients',
        translate: 'menu.clients',
        icon: 'account_circle',
        type: 'item',
        url: '/clients'
      },
      {
        id: 'invoices',
        title: 'Invoices',
        translate: 'menu.invoices',
        icon: 'credit_card',
        type: 'item',
        url: '/invoices'
      },
      {
        id: 'incomes',
        title: 'Incomes',
        translate: 'menu.incomes',
        icon: 'credit_card',
        type: 'item',
        url: '/incomes'
      },
      {
        id: 'expenses',
        title: 'Expenses',
        translate: 'menu.expenses',
        icon: 'receipt',
        type: 'item',
        url: '/expenses'
      },
      {
        id: 'expenditures',
        title: 'Expenditures',
        translate: 'menu.expenditures',
        icon: 'receipt',
        type: 'item',
        url: '/expenditures'
      },
      {
        id: 'salaries',
        title: 'Salaries',
        translate: 'menu.salaries',
        icon: 'money',
        type: 'collapsable',
        hidden: true,
        children: [
          {
            id: 'salaries-sales_payouts',
            title: 'Payouts',
            translate: 'menu.payouts',
            icon: 'money',
            type: 'item',
            url: '/payouts'
          },
          {
            id: 'salaries-salary_calculator',
            title: 'Salary Calculator',
            translate: 'menu.salaryCalculator',
            icon: 'money',
            type: 'item',
            url: '/salary-calculator'
          }
        ]
      },
      {
        id: 'settlements',
        title: 'Settlements',
        translate: 'menu.settlements',
        icon: 'money',
        type: 'collapsable',
        hidden: true,
        children: [
          {
            id: 'settlements-sales_settlements',
            title: 'Settlements',
            translate: 'menu.settlements',
            icon: 'money',
            type: 'item',
            url: '/settlements/list'
          },
          {
            id: 'settlements-settlement_calculator',
            title: 'Salary Calculator',
            translate: 'menu.settlementCalculator',
            icon: 'money',
            type: 'item',
            badge: {
              title: 'menu.new',
              translate: 'menu.new',
              bg: '#e8af04',
              fg: '#FFFFFF'
            },
            hidden: true,
            url: '/settlements/calculator'
          }
        ]
      },
      {
        id: 'work-hours',
        title: 'Work hours',
        translate: 'menu.workHours',
        icon: 'hourglass_empty',
        type: 'item',
        url: '/work-hours',
        badge: {
          title: 'menu.new',
          translate: 'menu.new',
          bg: '#e8af04',
          fg: '#FFFFFF'
        }
      },
      {
        id: 'subscriptions',
        title: 'Subscriptions',
        translate: 'menu.subscriptions',
        icon: 'lock',
        type: 'item',
        url: '/subscriptions',
        badge: {
          title: 'menu.new',
          translate: 'menu.new',
          bg: '#e8af04',
          fg: '#FFFFFF'
        }
      },
      {
        id: 'jobs',
        title: 'Jobs',
        translate: 'menu.jobs',
        icon: 'work_outline',
        type: 'item',
        url: '/jobs'
      },
      {
        id: 'work-shifts',
        title: 'Work Shifts',
        translate: 'menu.workShifts',
        icon: 'work_outline',
        type: 'collapsable',
        children: [
          {
            id: 'work-shifts-list',
            title: 'All Work Shifts',
            translate: 'menu.allWorkShifts',
            type: 'item',
            exactMatch: true,
            url: '/work-shifts'
          },
          {
            id: 'work-shifts-app',
            title: 'Work Shift App',
            translate: 'menu.workShiftApp',
            icon: 'app_registration',
            type: 'item',
            url: '/work-shifts/app'
          },
          {
            id: 'work-shifts-for-review',
            title: 'For Review',
            translate: 'menu.workShiftsForReview',
            icon: 'rate_review',
            type: 'item',
            url: '/work-shifts/list/ended'
          },
          {
            id: 'work-shifts-ongoing',
            title: 'Ongoing',
            translate: 'menu.workShiftsOngoing',
            icon: 'rate_review',
            type: 'item',
            url: '/work-shifts/list/started'
          },
          {
            id: 'work-shifts-rejected',
            title: 'Rejected',
            translate: 'menu.workShiftsRejected',
            icon: 'cancel',
            type: 'item',
            url: '/work-shifts/list/rejected'
          }
        ]
      },
      {
        id: 'work-notices',
        title: 'Work Notices',
        translate: 'menu.workNotices',
        icon: 'lock',
        type: 'item',
        url: '/work-notices'
      },
      {
        id: 'wallet',
        title: 'Wallets',
        translate: 'menu.wallets',
        icon: 'account_balance_wallet',
        type: 'item',
        url: '/wallets',
        badge: {
          title: '0',
          bg: '#F44336',
          fg: '#FFFFFF'
        }
      },
      {
        id: 'business-statements',
        title: 'Business Statements',
        translate: 'menu.businessStatements',
        icon: 'receipt',
        type: 'item',
        url: '/business-statements'
      },
      {
        id: 'insurances',
        title: 'Insurances',
        translate: 'menu.insurances',
        icon: 'account_balance',
        type: 'collapsable',
        hidden: true,
        children: [
          {
            id: 'insurances-my_insurances',
            title: 'My Insurances',
            translate: 'menu.myInsurances',
            icon: 'account_balance',
            type: 'item',
            url: '/insurances'
          },
          {
            id: 'insurances-yel_calculator',
            title: 'YEL Calculator',
            translate: 'menu.yelCalculator',
            icon: 'account_balance',
            type: 'item',
            url: '/yel-calculator'
          },
        ]
      },
      {
        id: 'offers',
        title: 'Offers',
        translate: 'menu.offers',
        icon: 'monetization_on',
        hidden: true,
        type: 'item',
        badge:
        {
          title: 'menu.todo',
          translate: 'menu.todo',
          bg: '#454545',
          fg: '#FFFFFF'
        },
        url: '/pages/coming-soon/offers'
      },
      {
        id: 'contracts',
        title: 'Contracts',
        translate: 'menu.contracts',
        icon: 'work',
        hidden: true,
        type: 'item',
        badge:
        {
          title: 'menu.todo',
          translate: 'menu.todo',
          bg: '#454545',
          fg: '#FFFFFF'
        },
        url: '/pages/coming-soon/contracts'
      },
      {
        id: 'reports',
        title: 'Reports',
        translate: 'menu.reports',
        icon: 'trending_up',
        type: 'collapsable',
        hidden: true,
        children: [
          {
            id: 'reports-income_report',
            title: 'Income Report',
            translate: 'menu.incomeReport',
            icon: 'file_copy',
            type: 'item',
            url: '/reports/income'
          },
          {
            id: 'reports-cumulative_report',
            title: 'Cumulative Report',
            translate: 'menu.cumulativeReport',
            icon: 'trending_up',
            type: 'item',
            url: '/reports/cumulative'
          },
          {
            id: 'reports-salary_report',
            title: 'Freelance Salary Report',
            translate: 'menu.freelanceSalaryReport',
            icon: 'trending_up',
            type: 'item',
            badge: {
              title: 'menu.new',
              translate: 'menu.new',
              bg: '#e8af04',
              fg: '#FFFFFF'
            },
            url: '/reports/salary'
          },
          {
            id: 'reports-invoices_report',
            title: 'Invoices Report',
            translate: 'menu.invoicesReport',
            icon: 'developer_board',
            type: 'item',
            url: '/reports/invoices'
          },
          {
            id: 'reports-payouts_report',
            title: 'Payouts Report',
            translate: 'menu.payoutsReport',
            icon: 'developer_board',
            type: 'item',
            url: '/reports/payouts'
          },
          {
            id: 'reports-settlements_report',
            title: 'Settlements Report',
            translate: 'menu.settlementsReport',
            icon: 'developer_board',
            type: 'item',
            url: '/reports/settlements'
          },
          {
            id: 'reports-work_certificate',
            title: 'Work Certificate',
            translate: 'menu.workCertificate',
            icon: 'trending_up',
            type: 'item',
            hidden: true,
            url: '/pages/coming-soon/work_certificate',
            badge:
            {
              title: 'menu.todo',
              translate: 'menu.todo',
              bg: '#454545',
              fg: '#FFFFFF'
            },
          },
          {
            id: 'reports-tax_report',
            title: 'Tax Report',
            translate: 'menu.taxReport',
            icon: 'trending_up',
            type: 'item',
            hidden: true,
            url: '/pages/coming-soon/tax_report',
            badge:
            {
              title: 'menu.todo',
              translate: 'menu.todo',
              bg: '#454545',
              fg: '#FFFFFF'
            },
          },
          {
            id: 'reports-work_shifts_report',
            title: 'Work Shifts Report',
            translate: 'menu.workShiftsReport',
            icon: 'report',
            type: 'item',
            badge: {
              title: 'menu.new',
              translate: 'menu.new',
              bg: '#e8af04',
              fg: '#FFFFFF'
            },
            url: '/reports/work-shifts'
          },
          {
            id: 'reports-taxi_rides_report',
            title: 'Taxi Rides Report',
            translate: 'menu.taxiRidesReport',
            icon: 'report',
            type: 'item',
            badge: {
              title: 'menu.new',
              translate: 'menu.new',
              bg: '#e8af04',
              fg: '#FFFFFF'
            },
            url: '/reports/taxi-rides'
          },
          {
            id: 'reports-accounting_events_report',
            title: 'Accounting Events Report',
            translate: 'menu.accountingEventsReport',
            icon: 'report',
            type: 'item',
            url: '/reports/accounting-events'
          },
        ]
      },
      {
        id: 'faq',
        title: 'FAQ',
        translate: 'menu.faq',
        icon: 'help',
        type: 'item',
        url: '/faq'
      },
      {
        id: 'other-services',
        title: 'Other Services',
        translate: 'menu.otherServices',
        icon: 'star',
        type: 'collapsable',
        hidden: true,
        children: [
          {
            id: 'other-services-work_id_card',
            title: 'Work ID-card',
            translate: 'menu.workIdCard',
            icon: 'star',
            type: 'item',
            hidden: true,
            badge:
            {
              title: 'TODO',
              translate: 'TODO',
              bg: '#454545',
              fg: '#FFFFFF'
            },
            url: '/pages/coming-soon/work-id-card'
          },
          {
            id: 'other-services-loans',
            title: 'Loans',
            translate: 'menu.loans',
            icon: 'free_breakfast',
            type: 'item',
            hidden: true,
            badge:
            {
              title: 'TODO',
              translate: 'TODO',
              bg: '#454545',
              fg: '#FFFFFF'
            },
            url: '/pages/coming-soon/loans'
          }
        ]
      },
      {
        id: 'marketing_menu',
        title: 'Marketing',
        translate: 'menu.marketing',
        type: 'collapsable',
        icon: 'shopping_cart',
        hidden: true,
        children: [
          {
            id: 'marketing_referrals',
            title: 'Referrals',
            translate: 'menu.referrals',
            type: 'item',
            icon: 'people',
            badge: {
              title: 'menu.new',
              translate: 'menu.new',
              bg: '#e8af04',
              fg: '#FFFFFF'
            },
            url: '/marketing/referrals',
          },
          {
            id: 'marketing_campaigns',
            title: 'Campaigns',
            translate: 'menu.campaigns',
            type: 'item',
            icon: 'people',
            badge: {
              title: 'menu.new',
              translate: 'menu.new',
              bg: '#e8af04',
              fg: '#FFFFFF'
            },
            url: '/marketing/campaigns'
          }
        ]
      },
      {
        id: 'accounting_events',
        title: 'Accounting Events',
        translate: 'menu.accountingEvents',
        type: 'item',
        icon: 'event',
        url: '/accounting-events'
      }
    ]
  },
  {
    id: 'admin_menu',
    title: 'Administration',
    translate: 'menu.administration',
    type: 'group',
    icon: 'apps',
    hidden: true,
    children: [
      {
        id: 'admin_operators',
        title: 'Operators',
        translate: 'menu.operators',
        type: 'item',
        icon: 'assignment_ind',
        url: '/operators/list'
      },
      {
        id: 'admin_operator_details',
        title: 'Operator Details',
        translate: 'menu.operatorDetails',
        type: 'item',
        icon: 'assignment_ind',
        url: '/operators/edit'
      },
      {
        id: 'admin_tax_cards',
        title: 'Tax Cards',
        translate: 'menu.taxCards',
        type: 'item',
        icon: 'card_membership',
        url: '/tax-cards/list'
      },
      {
        id: 'admin_invoices_review',
        title: 'Invoices for review',
        translate: 'menu.invoicesForReview',
        type: 'item',
        icon: 'credit_card',
        url: '/invoices/list/reviewing'
      },
      {
        id: 'admin_payouts_review',
        title: 'Payouts for review',
        translate: 'menu.payoutsForReview',
        type: 'item',
        icon: 'money',
        url: '/payouts/list/reviewing'
      },
      {
        id: 'admin_settlements_review',
        title: 'Settlements for review',
        translate: 'menu.settlementsForReview',
        type: 'item',
        icon: 'money',
        url: '/settlements/list/reviewing'
      },
      {
        id: 'admin_jobs_review',
        title: 'Jobs for review',
        translate: 'menu.jobsForReview',
        type: 'item',
        icon: 'work',
        url: '/jobs/created'
      },
      {
        id: 'admin_bank_transfer_transactions_review',
        title: 'Bank transfers for review',
        translate: 'menu.bankTransfersForReview',
        type: 'item',
        icon: 'swap_horiz',
        url: '/bank-transfer-transactions/reviewing'
      },
      {
        id: 'admin_users',
        title: 'Users',
        translate: 'menu.users',
        type: 'item',
        icon: 'supervisor_account',
        url: '/users'
      },
      {
        id: 'admin_user_fees',
        title: 'User fees',
        translate: 'menu.userFees',
        type: 'item',
        icon: 'money',
        url: '/user-fees'
      },
      {
        id: 'admin_liability_data',
        title: 'User fees',
        translate: 'menu.liabilityData',
        type: 'item',
        icon: 'money',
        url: '/liability-data'
      },
      {
        id: 'admin_talent-search',
        title: 'menu.talentSearch',
        translate: 'menu.talentSearch',
        icon: 'person_pin_circle',
        type: 'item',
        badge: {
          title: 'menu.new',
          translate: 'menu.new',
          bg: '#e8af04',
          fg: '#FFFFFF'
        },
        url: '/talent-search'
      },
      {
        id: 'admin_marketing',
        title: 'Marketing',
        translate: 'menu.marketing',
        type: 'collapsable',
        icon: 'shopping_cart',
        children: [
          {
            id: 'admin_referrals',
            title: 'Referrals',
            translate: 'menu.referrals',
            type: 'item',
            icon: 'people',
            badge: {
              title: 'menu.new',
              translate: 'menu.new',
              bg: '#e8af04',
              fg: '#FFFFFF'
            },
            url: '/marketing/referrals',
          },
          {
            id: 'admin_campaigns',
            title: 'Campaigns',
            translate: 'menu.campaigns',
            type: 'item',
            icon: 'people',
            badge: {
              title: 'menu.new',
              translate: 'menu.new',
              bg: '#e8af04',
              fg: '#FFFFFF'
            },
            url: '/marketing/campaigns'
          }
        ]
      },
      {
        id: 'admin_reports',
        title: 'Reports',
        translate: 'menu.reports',
        icon: 'trending_up',
        type: 'collapsable',
        hidden: true,
        children: [
          // {
          // 	id: 'reports-income_report',
          // 	title: 'Income Report',
          // 	translate: 'menu.incomeReport',
          // 	icon: 'file_copy',
          // 	hidden: true,
          // 	type: 'item',
          // 	url: '/reports/income'
          // },
          // {
          // 	id: 'reports-cumulative_report',
          // 	title: 'Cumulative Report',
          // 	translate: 'menu.cumulativeReport',
          // 	icon: 'trending_up',
          // 	hidden: true,
          // 	type: 'item',
          // 	url: '/reports/cumulative'
          // },
          {
            id: 'admin_reports-accounting',
            title: 'Accounting Report',
            translate: 'menu.accountingReport',
            icon: 'file_copy',
            hidden: true,
            type: 'item',
            url: '/reports/accounting'
          },
          {
            id: 'admin_reports-accounting-v2',
            title: 'Accounting Report v2',
            translate: 'menu.accountingReportV2',
            icon: 'file_copy',
            hidden: true,
            type: 'item',
            url: '/reports/accounting-v2'
          },
          {
            id: 'admin_reports-income_report',
            title: 'Tax Monthly Report',
            translate: 'menu.veroReport',
            icon: 'file_copy',
            hidden: true,
            type: 'item',
            url: '/reports/vero'
          },
          {
            id: 'admin_reports-monthly_report',
            title: 'Monthly Report',
            translate: 'menu.monthlyReport',
            icon: 'trending_up',
            hidden: true,
            type: 'item',
            url: '/reports/monthly'
          },
          {
            id: 'admin_reports-invoices_report',
            title: 'Invoices Report',
            translate: 'menu.invoicesReport',
            icon: 'developer_board',
            hidden: true,
            type: 'item',
            url: '/reports/invoices'
          },
          {
            id: 'admin_reports-payouts_report',
            title: 'Payouts Report',
            translate: 'menu.payoutsReport',
            icon: 'developer_board',
            hidden: true,
            type: 'item',
            url: '/reports/payouts'
          },
          {
            id: 'admin_reports-settlements_report',
            title: 'Settlements Report',
            translate: 'menu.settlementsReport',
            icon: 'developer_board',
            hidden: true,
            type: 'item',
            url: '/reports/settlements'
          }
        ]
      },
      {
        id: 'admin_adminbot',
        title: 'Admin Bot',
        translate: 'menu.adminbot',
        type: 'item',
        icon: 'help',
        badge: {
          title: 'menu.new',
          translate: 'menu.new',
          bg: '#e8af04',
          fg: '#FFFFFF'
        },
        url: '/adminbot'
      },
      {
        id: 'admin_importdata',
        title: 'Import Data',
        translate: 'menu.importData',
        type: 'item',
        icon: 'cloud_upload',
        badge: {
          title: 'menu.new',
          translate: 'menu.new',
          bg: '#e8af04',
          fg: '#FFFFFF'
        },
        url: '/import-data'
      },
      {
        id: 'admin_report-tool',
        title: 'Report Tool',
        hidden: true,
        translate: 'menu.reportTool',
        type: 'item',
        icon: 'assessment',
        url: '/pages/coming-soon'
      }
    ]
  },
  {
    id: 'finance_menu',
    title: 'Administration',
    translate: 'menu.finance',
    type: 'group',
    icon: 'apps',
    hidden: true,
    children: [
      {
        id: 'finance_dashboards',
        title: 'Dashboard',
        translate: 'menu.dashboard',
        icon: 'dashboard',
        type: 'item',
        url: '/dashboard'
      },
      {
        id: 'finance_clients',
        title: 'Clients',
        translate: 'menu.clients',
        icon: 'account_circle',
        type: 'item',
        url: '/clients'
      },
      {
        id: 'finance_invoices_review',
        title: 'Invoices for review',
        translate: 'menu.invoicesForReview',
        type: 'item',
        icon: 'credit_card',
        url: '/invoices/list/reviewing_finance'
      },
      {
        id: 'finance_bank_transfer_transactions_review',
        title: 'Bank Transfers for review',
        translate: 'menu.bankTransfersForReview',
        type: 'item',
        icon: 'bank',
        badge: {
          title: 'menu.new',
          translate: 'menu.new',
          bg: '#e8af04',
          fg: '#FFFFFF'
        },
        url: '/bank-transfer-transactions/reviewing'
      },
      {
        id: 'finance_reports-accounting-v2',
        title: 'Accounting Report v2',
        translate: 'menu.accountingReportV2',
        icon: 'file_copy',
        hidden: true,
        type: 'item',
        url: '/reports/accounting-v2'
      },
    ],
  },
  {
    id: 'taxi_menu',
    title: 'Taxi',
    translate: 'menu.taxiMenu',
    icon: 'car',
    type: 'group',
    hidden: true,
    children: [
      {
        id: 'taxi_invoice-form',
        title: 'Invoice editor',
        translate: 'menu.invoiceForm',
        type: 'item',
        icon: 'credit_card',
        url: '/invoices/creator'
      },
      {
        id: 'taxi_simple-invoice-form',
        title: 'Quick invoice',
        translate: 'menu.quickSimpleInvoiceForm',
        type: 'item',
        icon: 'credit_card',
        url: '/invoices/simple-invoice/standard_invoice'
      },
      {
        id: 'taxi_kelaov-simple-invoice-form',
        title: 'KELA invoice',
        translate: 'menu.simpleInvoiceForm',
        type: 'item',
        icon: 'credit_card',
        url: '/invoices/simple-invoice/kela_ov'
      },
      {
        id: 'taxi_contracts',
        title: 'Taxi contracts',
        translate: 'menu.taxiContracts',
        type: 'collapsable',
        // type: 'group',
        icon: 'account_circle',
        children: [
          {
            id: 'taxi_contracts-list',
            title: 'My contracts',
            translate: 'menu.taxiContractsList',
            type: 'item',
            icon: 'credit_card',
            badge:
            {
              title: 'menu.todo',
              translate: 'menu.todo',
              bg: '#454545',
              fg: '#FFFFFF'
            },
            url: '/taxi-contracts'
          },
          {
            id: 'taxi_contract-items',
            title: 'Contract items',
            translate: 'menu.taxiContractItems',
            type: 'item',
            icon: 'credit_card',
            badge:
            {
              title: 'menu.todo',
              translate: 'menu.todo',
              bg: '#454545',
              fg: '#FFFFFF'
            },
            url: '/taxi-contract-items'
          },
        ]
      },
      {
        id: 'taxi_taxi-companies',
        title: 'Taxi companies',
        translate: 'menu.taxiCompanies',
        type: 'item',
        icon: 'account_circle',
        url: '/users/list/taxi-company'
      },
      {
        id: 'taxi_sub-users',
        title: 'Taxi drivers',
        translate: 'menu.subUsers',
        type: 'item',
        icon: 'account_circle',
        url: '/sub-users'
      },
      {
        id: 'taxi_vehicles',
        title: 'Taxi vehicles',
        translate: 'menu.vehicles',
        type: 'item',
        icon: 'drive_eta',
        url: '/vehicles'
      },
      {
        id: 'taxi_rides',
        title: 'Taxi Rides',
        translate: 'menu.taxiRides',
        icon: 'local_taxi',
        type: 'item',
        url: '/taxi-rides'
      },
    ]
  }
];
