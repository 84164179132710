import { Expense } from "./expense.model";

export class MultiExpense {
  _id?: string;

  externalId?: string;

  createdAt?: Date;
  updatedAt?: Date;

  status: string;

  mileages?: Expense[];
  dailyAllowance?: Expense;
  expenseReceipts?: Expense[];

  // References
  ownedByUser?: string;
  operator?: string;

  constructor(multiExpense?: MultiExpense) {
    this._id = multiExpense?._id || '';

    this.externalId = multiExpense?.externalId || '';

    this.createdAt = multiExpense?.createdAt || new Date();
    this.updatedAt = multiExpense?.updatedAt || new Date();

    this.mileages = multiExpense?.mileages || [];
    this.dailyAllowance = multiExpense?.dailyAllowance || {};
    this.expenseReceipts = multiExpense?.expenseReceipts || [];
  }
}
